var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ESLAlert_1;
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { attr, jsonAttr, prop, listen } from '../../esl-utils/decorators';
import { isMatches } from '../../esl-utils/dom/traversing';
import { ESLToggleable } from '../../esl-toggleable/core';
import { CSSClassUtils } from '../../esl-utils/dom/class';
import { ESLTraversingQuery } from '../../esl-traversing-query/core';
/**
 * ESLAlert component
 * @author Julia Murashko, Alexey Stsefanovich (ala'n)
 *
 * ESLAlert is a component to show small notifications on your pages. ESLAlert can have multiple instances on the page.
 */
let ESLAlert = ESLAlert_1 = class ESLAlert extends ESLToggleable {
    /** Creates global alert instance (using body element as a base) */
    static init(options) {
        let alert = document.querySelector(`body > ${ESLAlert_1.is}`);
        if (!alert) {
            alert = Object.assign(ESLAlert_1.create(), options || {});
            document.body.appendChild(alert);
        }
        return alert;
    }
    mergeDefaultParams(params) {
        const type = this.constructor;
        return Object.assign({}, type.defaultConfig, type.DEFAULT_PARAMS, this.defaultParams || {}, params || {});
    }
    attributeChangedCallback(attrName, oldVal, newVal) {
        if (!this.connected)
            return;
        if (attrName === 'target') {
            this.$target = ESLTraversingQuery.first(this.target);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAttribute('role', this.getAttribute('role') || 'alert');
        this.$content = document.createElement('div');
        this.$content.className = 'esl-alert-content';
        this.innerHTML = '';
        this.appendChild(this.$content);
        if (this.target) {
            this.$target = ESLTraversingQuery.first(this.target, this);
        }
    }
    /** Target element to listen to activation events */
    get $target() {
        return this._$target;
    }
    set $target($el) {
        this._$target = $el;
        this.$$on(this._onShowRequest);
        this.$$on(this._onHideRequest);
    }
    onShow(params) {
        if (this._clearTimeout)
            window.clearTimeout(this._clearTimeout);
        if (params.html || params.text) {
            this.render(params);
            super.onShow(params);
        }
        this.hide(params);
    }
    onHide(params) {
        super.onHide(params);
        this._clearTimeout = window.setTimeout(() => this.clear(), params.hideTime);
    }
    render({ text, html, cls }) {
        CSSClassUtils.remove(this, this.activeCls);
        CSSClassUtils.add(this, this.activeCls = cls);
        if (html)
            this.$content.innerHTML = html;
        if (text)
            this.$content.textContent = text;
    }
    clear() {
        this.$content.innerHTML = '';
        CSSClassUtils.remove(this, this.activeCls);
    }
    buildRequestParams(e) {
        const detail = e.detail || {};
        if (!isMatches(this, detail.match))
            return null;
        if (e.type === this.SHOW_REQUEST_EVENT)
            return Object.assign({}, detail, { force: true });
        if (e.type === this.HIDE_REQUEST_EVENT)
            return Object.assign({ hideDelay: 0 }, detail, { force: true });
        return null;
    }
    _onHideRequest(e) {
        super._onHideRequest(e);
        e.stopPropagation();
    }
    _onShowRequest(e) {
        super._onShowRequest(e);
        e.stopPropagation();
    }
};
ESLAlert.is = 'esl-alert';
ESLAlert.observedAttributes = ['target'];
/**
 * Legacy default show/hide params for all ESLAlert instances
 * @deprecated Use {@link ESLAlert.DEFAULT_PARAMS} instead
 */
ESLAlert.defaultConfig = {};
/** Default show/hide params for all ESLAlert instances */
ESLAlert.DEFAULT_PARAMS = {
    hideTime: 300,
    hideDelay: 2500
};
__decorate([
    prop('esl:alert:show')
], ESLAlert.prototype, "SHOW_REQUEST_EVENT", void 0);
__decorate([
    prop('esl:alert:hide')
], ESLAlert.prototype, "HIDE_REQUEST_EVENT", void 0);
__decorate([
    attr({ defaultValue: '::parent' })
], ESLAlert.prototype, "target", void 0);
__decorate([
    jsonAttr()
], ESLAlert.prototype, "defaultParams", void 0);
__decorate([
    listen({ inherit: true, target: (el) => el.$target })
], ESLAlert.prototype, "_onHideRequest", null);
__decorate([
    listen({ inherit: true, target: (el) => el.$target })
], ESLAlert.prototype, "_onShowRequest", null);
ESLAlert = ESLAlert_1 = __decorate([
    ExportNs('Alert')
], ESLAlert);
export { ESLAlert };
