var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { isElement } from '../../esl-utils/dom/api';
import { attr, prop, ready } from '../../esl-utils/decorators';
import { ESLTraversingQuery } from '../../esl-traversing-query/core';
import { ESLToggleablePlaceholder } from '../../esl-toggleable/core';
import { ESLBaseTrigger } from './esl-base-trigger';
let ESLTrigger = class ESLTrigger extends ESLBaseTrigger {
    /** Target observable Toggleable */
    get $target() {
        return this._$target;
    }
    set $target(newPopupInstance) {
        this.$$off(this._onTargetStateChange);
        this._$target = newPopupInstance;
        this.$$on(this._onTargetStateChange);
        this._onTargetStateChange();
    }
    /** Element target to setup aria attributes */
    get $a11yTarget() {
        return this.a11yTarget ? this.querySelector(this.a11yTarget) : this;
    }
    connectedCallback() {
        super.connectedCallback();
        this.updateTargetFromSelector();
        this.initA11y();
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        if (!this.connected)
            return;
        if (attrName === 'target')
            return this.updateTargetFromSelector();
    }
    /** Update `$target` Toggleable  from `target` selector */
    updateTargetFromSelector() {
        if (!this.target)
            return;
        this.$target = ESLTraversingQuery.first(this.target, this);
        if (this.$target instanceof ESLToggleablePlaceholder && this.$target.$origin) {
            // change target if it is an instance of the placeholder element
            this.$target = this.$target.$origin;
        }
    }
    /** Check if the event target should be ignored */
    isTargetIgnored(target) {
        if (!target || !isElement(target) || !this.ignore)
            return false;
        const $ignore = target.closest(this.ignore);
        // Ignore only inner elements (but do not ignore the trigger itself)
        return !!$ignore && $ignore !== this && this.contains($ignore);
    }
};
ESLTrigger.is = 'esl-trigger';
ESLTrigger.observedAttributes = ['target'];
__decorate([
    prop('esl:change:active')
], ESLTrigger.prototype, "CHANGE_EVENT", void 0);
__decorate([
    attr({ defaultValue: 'a[href]' })
], ESLTrigger.prototype, "ignore", void 0);
__decorate([
    attr({ defaultValue: '' })
], ESLTrigger.prototype, "target", void 0);
__decorate([
    attr({ defaultValue: 'toggle' })
], ESLTrigger.prototype, "mode", void 0);
__decorate([
    attr({ defaultValue: '' })
], ESLTrigger.prototype, "a11yTarget", void 0);
__decorate([
    ready
], ESLTrigger.prototype, "connectedCallback", null);
ESLTrigger = __decorate([
    ExportNs('Trigger')
], ESLTrigger);
export { ESLTrigger };
