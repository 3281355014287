import { ESLMixinElement } from '@exadel/esl/modules/esl-mixin-element/core';
export class ESLDemoBackLink extends ESLMixinElement {
    connectedCallback() {
        super.connectedCallback();
        if (!document.referrer)
            return;
        const refUrl = new URL(document.referrer);
        if (refUrl.host !== window.location.host)
            return;
        this.$$attr('href', document.referrer);
        this.$$attr(ESLDemoBackLink.is, false);
    }
}
ESLDemoBackLink.is = 'esl-d-back-link';
