import { resolveDomTarget } from '../abstract/dom-target';
/** @returns true if attribute presented */
export function hasAttr($el, name) {
    if (Array.isArray($el))
        return $el.every((element) => hasAttr(element, name));
    if (!($el = resolveDomTarget($el)))
        return false;
    return $el.hasAttribute(name);
}
export function getAttr($el, name, fallback = null) {
    if (Array.isArray($el))
        return getAttr($el[0], name);
    if (!($el = resolveDomTarget($el)))
        return null;
    const value = $el.getAttribute(name);
    return value === null ? fallback : value;
}
/** Sets attribute */
export function setAttr($el, name, value) {
    if (Array.isArray($el)) {
        $el.forEach((element) => setAttr(element, name, value));
        return;
    }
    if (!($el = resolveDomTarget($el)))
        return;
    if (value === undefined || value === null || value === false) {
        $el.removeAttribute(name);
    }
    else {
        $el.setAttribute(name, value === true ? '' : value);
    }
}
/** Gets attribute value from the closest element with group behavior settings */
export function getClosestAttr($el, attrName) {
    if (!($el = resolveDomTarget($el)))
        return null;
    const $closest = $el.closest(`[${attrName}]`);
    return $closest ? $closest.getAttribute(attrName) : null;
}
