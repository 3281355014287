/**
 * Postpone action after next render
 */
export const afterNextRender = (callback) => requestAnimationFrame(() => requestAnimationFrame(callback));
/**
 * Postpone action after a couple of renders
 */
export const skipOneRender = (callback) => requestAnimationFrame(() => afterNextRender(callback));
/**
 * Decorate function to schedule execution after next render
 * @returns decorated function
 */
export const rafDecorator = (fn) => {
    let lastArgs = null; // null if no calls requested
    return function (...args) {
        if (lastArgs === null) {
            requestAnimationFrame(() => {
                lastArgs && fn.call(this, ...lastArgs);
                lastArgs = null;
            });
        }
        lastArgs = args;
    };
};
