var _a;
import { uniq } from '../../misc/array';
import { overrideEvent } from './misc';
/** Key to store listeners on the {@link SyntheticEventTarget} instance*/
const LISTENERS = (window.Symbol || String)('_listeners');
/**
 * Synthetic implementation of EventTarget
 * Replicates behavior of native event
 * Doesn't give explicit access to callback storage
 */
export class SyntheticEventTarget {
    constructor() {
        this[_a] = {};
    }
    getEventListeners(type) {
        if (typeof type !== 'string')
            return uniq(Object.values(this[LISTENERS]).flat(1));
        return this[LISTENERS][type] || [];
    }
    hasEventListener(type) {
        if (typeof type === 'string')
            return this.getEventListeners(type).length > 0;
        return this.hasEventListener(this.constructor.DEFAULT_EVENT);
    }
    addEventListener(type, callback) {
        if (typeof type !== 'string')
            return this.addEventListener(this.constructor.DEFAULT_EVENT, type);
        validateEventListenerType(callback);
        const listeners = this[LISTENERS][type];
        if (listeners === null || listeners === void 0 ? void 0 : listeners.includes(callback))
            return;
        if (listeners)
            listeners.push(callback);
        else
            Object.assign(this[LISTENERS], { [type]: [callback] });
    }
    removeEventListener(type, callback) {
        if (typeof type !== 'string')
            return this.removeEventListener(this.constructor.DEFAULT_EVENT, type);
        validateEventListenerType(callback);
        const listeners = this[LISTENERS][type];
        if (!listeners)
            return;
        this[LISTENERS][type] = listeners.filter((cb) => cb !== callback);
    }
    dispatchEvent(e) {
        overrideEvent(e, 'currentTarget', this);
        if (!e.target)
            overrideEvent(e, 'target', this);
        if (!e.srcElement)
            overrideEvent(e, 'srcElement', e.target);
        this.getEventListeners(e.type).forEach((listener) => {
            if (typeof listener === 'function')
                listener.call(this, e);
            else
                listener.handleEvent.call(listener, e);
        });
        return e.defaultPrevented;
    }
}
_a = LISTENERS;
// Event type to use in the shortcut calls
SyntheticEventTarget.DEFAULT_EVENT = 'change';
function validateEventListenerType(callback) {
    if (!callback || typeof callback !== 'function' && typeof callback.handleEvent !== 'function') {
        throw Error('Callback should be a function or EventListenerObject');
    }
}
