import { createDeferred } from './promise/defered';
import { promisifyTimeout } from './promise/timeout';
import { promisifyTransition } from './promise/animation';
import { promisifyEvent, promisifyMarker } from './promise/event';
import { promisifiedTry, tryUntil } from './promise/try-until';
import { promisifyNextRender } from './promise/raf';
export { createDeferred, promisifyTimeout, promisifyEvent, promisifyMarker, promisifyNextRender, promisifiedTry, promisifyTransition };
export { tryUntil };
/**
 * Call async callback in a sequence passed number of times
 * Initial call starts as a microtask
 * @param callback - async chain function
 * @param count - count o calls
 * @returns sequence end promise
 */
export function repeatSequence(callback, count = 1) {
    if (count < 1)
        return Promise.reject();
    if (count === 1)
        return Promise.resolve().then(callback);
    return repeatSequence(callback, count - 1).then(callback);
}
/**
 * Safe wrap for Promise.resolve to use in Promise chain
 * @example
 * `const resolvedPromise = rejectedPromise.catch(resolvePromise);`
 */
export function resolvePromise(arg) {
    return Promise.resolve(arg);
}
/**
 * Safe wrap for Promise.reject to use in Promise chain
 * @example
 * `const rejectedPromise = resolvedPromise.then(rejectPromise);`
 */
export function rejectPromise(arg) {
    return Promise.reject(arg);
}
/**
 * Promise utils helper class
 * Note: use individual methods in case you need correct "tree shaking"
 * @deprecated use separate function extend
 */
export class PromiseUtils {
}
PromiseUtils.fromTimeout = promisifyTimeout;
PromiseUtils.fromEvent = promisifyEvent;
PromiseUtils.fromMarker = promisifyMarker;
PromiseUtils.repeat = repeatSequence;
PromiseUtils.tryUntil = tryUntil;
PromiseUtils.deferred = createDeferred;
PromiseUtils.resolve = resolvePromise;
PromiseUtils.reject = rejectPromise;
