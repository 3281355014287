var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ESLScreenDPR_1;
import { ExportNs } from '../../../esl-utils/environment/export-ns';
import { isBot, isSafari } from '../../../esl-utils/environment/device-detector';
/**
 * DPR preprocessor. Used to replace DPR shortcuts.
 * @author Alexey Stsefanovich (ala'n)
 *
 * @implements IMediaQueryPreprocessor statically
 */
let ESLScreenDPR = ESLScreenDPR_1 = class ESLScreenDPR {
    static toDPI(dpr) {
        return (96 * dpr).toFixed(1);
    }
    static process(match) {
        if (!ESLScreenDPR_1.VALUE_REGEXP.test(match))
            return;
        const dpr = parseFloat(match);
        if (dpr < 0 || isNaN(dpr))
            return;
        if (ESLScreenDPR_1.ignoreBotsDpr && isBot && dpr > 1)
            return 'not all';
        if (isSafari)
            return `(-webkit-min-device-pixel-ratio: ${dpr})`;
        return `(min-resolution: ${ESLScreenDPR_1.toDPI(dpr)}dpi)`;
    }
};
ESLScreenDPR.VALUE_REGEXP = /(\d(\.\d)?)x/;
/** Option to exclude dpr greater then 2 for bots */
ESLScreenDPR.ignoreBotsDpr = false;
ESLScreenDPR = ESLScreenDPR_1 = __decorate([
    ExportNs('ScreenDPR')
], ESLScreenDPR);
export { ESLScreenDPR };
