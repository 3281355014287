var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLPopup } from '../../esl-popup/core';
import { memoize, boolAttr } from '../../esl-utils/decorators';
let ESLTooltip = class ESLTooltip extends ESLPopup {
    /** Shared instanse of Tooltip */
    static get sharedInstance() {
        return document.createElement('esl-tooltip');
    }
    /** Active state marker */
    static get open() {
        return this.sharedInstance.open;
    }
    /** Changes the element state to active */
    static show(params = {}) {
        this.sharedInstance.show(params);
    }
    /** Changes the element state to inactive */
    static hide(params = {}) {
        this.sharedInstance.hide(params);
    }
    connectedCallback() {
        super.connectedCallback();
        this.classList.add(ESLPopup.is);
        this.classList.toggle('disable-arrow', this.disableArrow);
        this.tabIndex = 0;
    }
    /** Sets initial state of the Tooltip */
    setInitialState() { }
    /** Actions to execute on show Tooltip. */
    onShow(params) {
        if (params.disableArrow)
            this.disableArrow = params.disableArrow;
        if (params.text)
            this.innerText = params.text;
        if (params.html)
            this.innerHTML = params.html;
        if (params.text || params.html)
            memoize.clear(this, '$arrow');
        this.dir = params.dir || '';
        this.lang = params.lang || '';
        this.parentNode !== document.body && document.body.appendChild(this);
        super.onShow(params);
    }
    /** Actions to execute on Tooltip hiding. */
    onHide(params) {
        super.onHide(params);
        this.parentNode === document.body && document.body.removeChild(this);
    }
};
ESLTooltip.is = 'esl-tooltip';
/** Default params to pass into the tooltip on show/hide actions */
ESLTooltip.DEFAULT_PARAMS = Object.assign(Object.assign({}, ESLPopup.DEFAULT_PARAMS), { position: 'top', hideDelay: 300 });
__decorate([
    boolAttr()
], ESLTooltip.prototype, "disableArrow", void 0);
__decorate([
    memoize()
], ESLTooltip, "sharedInstance", null);
ESLTooltip = __decorate([
    ExportNs('Tooltip')
], ESLTooltip);
export { ESLTooltip };
