var _a;
import { overrideEvent } from '../../../esl-utils/dom/events/misc';
/**
 * Swipe event dispatched by {@link ESLSwipeGestureTarget}
 */
export class ESLSwipeGestureEvent extends UIEvent {
    /** @returns whether swipe direction is vertical or not */
    get isVertical() {
        return this.direction === 'up' || this.direction === 'down';
    }
    constructor(target, swipeInfo) {
        super(_a.TYPE, { bubbles: false, cancelable: true });
        overrideEvent(this, 'target', target);
        Object.assign(this, swipeInfo);
    }
    /** Creates {@link ESLSwipeGestureEvent} based on {@link ESLSwipeGestureEventInfo} */
    static fromConfig(target, swipeInfo) {
        return new _a(target, swipeInfo);
    }
}
_a = ESLSwipeGestureEvent;
ESLSwipeGestureEvent.TYPE = 'swipe';
/** @deprecated Use {@link TYPE} instead */
ESLSwipeGestureEvent.type = _a.TYPE;
