/**
 * @returns Promise that will be resolved by dispatching `event` on `target`
 * Or it will be rejected in `timeout` if it's specified
 * Optional `options` for addEventListener can be also specified
 */
export function promisifyEvent(target, event, timeout, options) {
    return new Promise((resolve, reject) => {
        const signal = options === null || options === void 0 ? void 0 : options.signal;
        if (signal === null || signal === void 0 ? void 0 : signal.aborted)
            return rejectOnSignal();
        function eventCallback(e) {
            target.removeEventListener(event, eventCallback, options);
            e ? resolve(e) : reject(new Error('Rejected by timeout'));
            signalCallback();
        }
        function signalCallback(e) {
            signal === null || signal === void 0 ? void 0 : signal.removeEventListener('abort', signalCallback);
            e && rejectOnSignal();
        }
        function rejectOnSignal() {
            reject(new Error('Rejected by abort signal'));
        }
        target.addEventListener(event, eventCallback, options);
        if (typeof timeout === 'number' && timeout >= 0) {
            setTimeout(eventCallback, timeout);
        }
        signal === null || signal === void 0 ? void 0 : signal.addEventListener('abort', signalCallback);
    });
}
/**
 * Short helper to make Promise from element state marker
 * @returns Promise that will be resolved if the target `marker` property is truthful or `event` is dispatched
 * @example
 * `const imgReady = promisifyMarker(eslImage, 'ready');`
 */
export function promisifyMarker(target, marker, event = marker) {
    if (target[marker])
        return Promise.resolve(target);
    return promisifyEvent(target, event).then(() => target);
}
