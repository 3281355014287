import { identity, resolveProperty } from '../misc/functions';
import { parseString, toKebabCase } from '../misc/format';
import { getAttr, getClosestAttr, setAttr } from '../dom/attr';
const buildAttrName = (propName, dataAttr) => dataAttr ? `data-${toKebabCase(propName)}` : toKebabCase(propName);
/**
 * Decorator to map current property to element attribute value.
 * Maps string type property.
 * @param config - mapping configuration. See {@link AttrDescriptor}
 */
export const attr = (config = {}) => {
    return (target, propName) => {
        const attrName = buildAttrName(config.name || propName, !!config.dataAttr);
        const inheritAttrName = typeof config.inherit === 'string' ? config.inherit : attrName;
        function get() {
            const val = config.inherit ? getAttr(this, attrName) || getClosestAttr(this, inheritAttrName) : getAttr(this, attrName);
            if (val === null && 'defaultValue' in config)
                return resolveProperty(config.defaultValue, this);
            return (config.parser || parseString)(val);
        }
        function set(value) {
            setAttr(this, attrName, (config.serializer || identity)(value));
        }
        Object.defineProperty(target, propName, config.readonly ? { get } : { get, set });
        return {};
    };
};
