var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../../esl-utils/environment/export-ns';
import { ESLCarouselPlugin } from '../esl-carousel.plugin';
import { listen } from '../../../esl-utils/decorators';
import { ARROW_DOWN, ARROW_LEFT, ARROW_RIGHT, ARROW_UP } from '../../../esl-utils/dom/keys';
/**
 * {@link ESLCarousel} Keyboard arrow support
 *
 * @author Alexey Stsefanovich (ala'n)
 */
let ESLCarouselKeyboardMixin = class ESLCarouselKeyboardMixin extends ESLCarouselPlugin {
    /** @returns key code for next navigation */
    get nextKey() {
        return this.$host.config.vertical ? ARROW_DOWN : ARROW_RIGHT;
    }
    /** @returns key code for prev navigation */
    get prevKey() {
        return this.$host.config.vertical ? ARROW_UP : ARROW_LEFT;
    }
    /** Handles `keydown` event */
    _onKeydown(event) {
        if (!this.$host || this.$host.animating || this.config.command === 'none')
            return;
        if (event.key === this.nextKey)
            this.$host.goTo(`${this.config.command || 'slide'}:next`);
        if (event.key === this.prevKey)
            this.$host.goTo(`${this.config.command || 'slide'}:prev`);
    }
};
ESLCarouselKeyboardMixin.is = 'esl-carousel-keyboard';
ESLCarouselKeyboardMixin.DEFAULT_CONFIG_KEY = 'command';
__decorate([
    listen('keydown')
], ESLCarouselKeyboardMixin.prototype, "_onKeydown", null);
ESLCarouselKeyboardMixin = __decorate([
    ExportNs('Carousel.Keyboard')
], ESLCarouselKeyboardMixin);
export { ESLCarouselKeyboardMixin };
