var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { attr, prop, listen } from '@exadel/esl/modules/esl-utils/decorators';
import { ESLIntersectionTarget } from '@exadel/esl/modules/esl-event-listener/core';
import { ESLToggleable } from '@exadel/esl/modules/esl-toggleable/core';
export class ESLDemoBanner extends ESLToggleable {
    get $alert() {
        return this.querySelector('.alert');
    }
    /** Check if the coolie is active */
    get hasCookie() {
        const { cookieName } = this;
        return !!cookieName && document.cookie.indexOf(`${cookieName}=true`) !== -1;
    }
    /** Store cookie {@link cookieName} for {@link cookieTime} period */
    registerCookie(expireDays = this.cookieTime) {
        const { cookieName } = this;
        if (!cookieName)
            return;
        const expires = new Date(Date.now() + expireDays * 864e5).toUTCString();
        document.cookie = `${cookieName}=true; path=/; expires=${expires};`;
    }
    onShow(params) {
        var _a;
        super.onShow(params);
        (_a = this.$alert) === null || _a === void 0 ? void 0 : _a.classList.add('in');
        this.$$off(this._onIntersect);
    }
    onHide(param) {
        super.onHide(param);
        if (param.initiator === 'close')
            this.registerCookie();
    }
    _onIntersect(e) {
        this.toggle(e.isIntersecting, { showDelay: 750 });
    }
}
ESLDemoBanner.is = 'esl-d-banner';
__decorate([
    attr({ parser: (str) => str && encodeURIComponent(str).trim() })
], ESLDemoBanner.prototype, "cookieName", void 0);
__decorate([
    attr({ defaultValue: 14, parser: parseInt })
], ESLDemoBanner.prototype, "cookieTime", void 0);
__decorate([
    prop(true)
], ESLDemoBanner.prototype, "closeOnEsc", void 0);
__decorate([
    prop('.close-button')
], ESLDemoBanner.prototype, "closeTrigger", void 0);
__decorate([
    listen({
        event: 'intersects',
        target: ($this) => ESLIntersectionTarget.for($this, { threshold: 0.99 }),
        condition: ($this) => !$this.hasCookie
    })
], ESLDemoBanner.prototype, "_onIntersect", null);
