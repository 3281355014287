import { Rect } from './rect';
import { isElement } from './api';
import { getViewportRect } from './window';
import { getListScrollParents } from './scroll';
import { findClosestBy, findHost } from './traversing';
/** @returns if the exact passed Element hidden with CSS visibility */
const isHiddenPredicate = (el) => isElement(el) && getComputedStyle(el).visibility === 'hidden';
/** @returns if the exact passed Element is transparent (CSS opacity is 0) */
const isTransparentPredicate = (el) => isElement(el) && getComputedStyle(el).opacity === '0';
/**
 * Checks if the specified element is visible
 * @param el - element to be checked
 * @param options - object of additional visibility options to include
 */
export function isVisible(el, options = { visibility: true }) {
    if (!el.getClientRects().length)
        return false;
    if (options.visibility && findHost(el, isHiddenPredicate))
        return false;
    if (options.opacity && findClosestBy(el, isTransparentPredicate))
        return false;
    return !(options.viewport && !isInViewport(el));
}
/**
 * Checks if the specified element is inside the viewport
 * @param el - element to be checked
 */
export function isInViewport(el) {
    const wndIntersection = Rect.from(el).intersect(getViewportRect());
    if (wndIntersection.area === 0)
        return false;
    return !getListScrollParents(el).some((parent) => Rect.from(parent).intersect(wndIntersection).area === 0);
}
