import { Rect } from '../../esl-utils/dom/rect';
/**
 * Checks that the position along the horizontal axis
 * @param position - name of position
 */
export function isOnHorizontalAxis(position) {
    return ['left', 'right'].includes(position);
}
/**
 * Checks whether the specified position corresponds to the starting side
 * @param position - name of position
 */
function isStartingSide(position) {
    return ['left', 'top'].includes(position);
}
/**
 * Calculates the position of the popup on the minor axis
 * @param cfg - popup position config
 * @param centerPosition - position of the center of the trigger on the minor axis
 * @param dimensionName - the name of dimension (height or width)
 */
function calcPopupPositionByMinorAxis(cfg, centerPosition, dimensionName) {
    return centerPosition - cfg.arrow[dimensionName] / 2 - cfg.marginArrow - calcUsableSizeForArrow(cfg, dimensionName) * cfg.offsetArrowRatio;
}
/**
 * Calculates Rect for given popup position config.
 * @param cfg - popup position config
 * */
function calcPopupBasicRect(cfg) {
    const { position, inner, element, hasInnerOrigin } = cfg;
    let x = isOnHorizontalAxis(position) ? 0 : calcPopupPositionByMinorAxis(cfg, inner.cx, 'width');
    let y = isOnHorizontalAxis(position) ? calcPopupPositionByMinorAxis(cfg, inner.cy, 'height') : 0;
    switch (position) {
        case 'left':
            x = (hasInnerOrigin ? inner.right : inner.x) - element.width;
            break;
        case 'right':
            x = hasInnerOrigin ? inner.x : inner.right;
            break;
        case 'bottom':
            y = hasInnerOrigin ? inner.y : inner.bottom;
            break;
        default:
            y = (hasInnerOrigin ? inner.bottom : inner.y) - element.height;
            break;
    }
    return new Rect(x, y, element.width, element.height);
}
/**
 * Calculates position for all sub-parts of popup for given popup position config.
 * @param cfg - popup position config
 * */
function calcBasicPosition(cfg) {
    const popup = calcPopupBasicRect(cfg);
    const arrow = {
        x: calcArrowPosition(cfg, 'width'),
        y: calcArrowPosition(cfg, 'height'),
    };
    return { arrow, popup, placedAt: cfg.position };
}
/**
 * Gets opposite position.
 * @param position - name of position
 * */
function getOppositePosition(position) {
    return ({
        top: 'bottom',
        left: 'right',
        right: 'left',
        bottom: 'top'
    }[position] || position);
}
/**
 * Checks and updates popup and arrow positions to fit on major axis.
 * @param cfg - popup position config
 * @param value - current popup's position value
 * @returns updated popup position value
 * */
function fitOnMajorAxis(cfg, value) {
    if (!['fit', 'fit-major'].includes(cfg.behavior))
        return value;
    const intersectionRatio = cfg.intersectionRatio[cfg.position] || 0;
    const leftComparand = isStartingSide(cfg.position) ? value.popup[cfg.position] : cfg.outer[cfg.position];
    const rightComparand = isStartingSide(cfg.position) ? cfg.outer[cfg.position] : value.popup[cfg.position];
    const isRequireAdjusting = intersectionRatio > 0 || leftComparand < rightComparand;
    return isRequireAdjusting ? adjustAlongMajorAxis(cfg, value) : value;
}
/**
 * Updates popup and arrow positions to fit on major axis.
 * @param cfg - popup position config
 * @param value - current popup's position value
 * @returns updated popup position value
 * */
function adjustAlongMajorAxis(cfg, value) {
    const popup = isStartingSide(cfg.position)
        ? adjustForStartingSide(cfg, value.popup)
        : adjustForEndingSide(cfg, value.popup);
    const placedAt = getOppositePosition(cfg.position);
    return Object.assign(Object.assign({}, value), { popup, placedAt });
}
/**
 * Updates popup rect to fit on major axis in case positioning on the starting side.
 * @param cfg - popup position config
 * @param popup - popup rect
 * @returns updated popup rect
 * */
function adjustForStartingSide(cfg, popup) {
    const { position, inner, hasInnerOrigin } = cfg;
    let { x, y } = popup;
    switch (position) {
        case 'left':
            x = hasInnerOrigin ? inner.x : inner.right;
            break;
        case 'top':
            y = hasInnerOrigin ? inner.y : inner.bottom;
            break;
    }
    return new Rect(x, y, popup.width, popup.height);
}
/**
 * Updates popup rect to fit on major axis in case positioning on the ending side.
 * @param cfg - popup position config
 * @param popup - popup rect
 * @returns updated popup rect
 * */
function adjustForEndingSide(cfg, popup) {
    const { position, inner, hasInnerOrigin } = cfg;
    let { x, y } = popup;
    switch (position) {
        case 'right':
            x = (hasInnerOrigin ? inner.right : inner.x) - popup.width;
            break;
        case 'bottom':
            y = (hasInnerOrigin ? inner.bottom : inner.y) - popup.height;
            break;
    }
    return new Rect(x, y, popup.width, popup.height);
}
/**
 * Calculates adjust for popup position to fit container bounds
 * @param cfg - popup position config
 * @param diffCoord - distance between the popup and the outer (container) bounding
 * @param arrowCoord - coordinate of the arrow
 * @param isStart - should it rely on the starting side?
 * @returns adjustment value for the coordinates of the arrow and the popup
 */
function adjustAlignmentBySide(cfg, diffCoord, arrowCoord, isStart) {
    let arrowAdjust = 0;
    if (isStart ? diffCoord < 0 : diffCoord > 0) {
        arrowAdjust = diffCoord;
        const newCoord = arrowCoord + arrowAdjust;
        const dimension = isOnHorizontalAxis(cfg.position) ? 'height' : 'width';
        const arrowLimit = cfg.marginArrow + (isStart ? 0 : calcUsableSizeForArrow(cfg, dimension));
        if (isStart ? newCoord < arrowLimit : newCoord > arrowLimit) {
            arrowAdjust -= newCoord - arrowLimit;
        }
    }
    return arrowAdjust;
}
/**
 * Sets up the configuration for adjusting position along the minor axis
 * @param cfg - popup position config
 * @param popup - current popup's position value
 * @returns configuration for adjusting position along the minor axis
 */
function setupAlignmentBySide(cfg, popup) {
    const isHorizontal = isOnHorizontalAxis(cfg.position);
    const start = isHorizontal ? 'y' : 'x';
    const end = isHorizontal ? 'bottom' : 'right';
    const dimension = isHorizontal ? 'height' : 'width';
    const isOutAtStart = popup[start] < cfg.outer[start];
    const isOutAtEnd = popup[end] > cfg.outer[end];
    const isWider = cfg.outer[dimension] < cfg.element[dimension];
    return { isHorizontal, start, end, isOutAtStart, isOutAtEnd, isWider };
}
/**
 * Updates popup and arrow positions to fit on minor axis.
 * @param cfg - popup position config
 * @param value - current popup's position value
 * @returns updated popup position value
 * */
function fitOnMinorAxis(cfg, value) {
    if (!['fit', 'fit-minor'].includes(cfg.behavior))
        return value;
    const { popup, arrow } = value;
    const { isHorizontal, start, end, isOutAtStart, isOutAtEnd, isWider } = setupAlignmentBySide(cfg, popup);
    // nothing to do when there is no outing
    if (!isOutAtStart && !isOutAtEnd)
        return value;
    // start-side adjusting happens if there is only start-side outing or LTR content direction
    const isStarting = isOutAtStart && (!isOutAtEnd || !cfg.isRTL);
    // the side for calculating the distance between the popup and the outer (container) bounding should be:
    // - when the popup is wider than the container the diff side should depend on the text direction
    //   (start side for LTR, end side for RTL)
    // - else we should choose start side if start-side outing or end side if end-side outing
    const diffSide = (isWider ? !cfg.isRTL : isStarting) ? start : end;
    const diff = popup[diffSide] - cfg.outer[diffSide];
    const shift = adjustAlignmentBySide(cfg, diff, arrow[start], isStarting);
    arrow[start] += shift;
    return Object.assign(Object.assign({}, value), { popup: isHorizontal ? popup.shift(0, -shift) : popup.shift(-shift, 0), arrow });
}
/**
 * Calculates the usable size available for the arrow
 * @param cfg - popup position config
 * @param dimensionName - the name of dimension (height or width)
 */
function calcUsableSizeForArrow(cfg, dimensionName) {
    return cfg.element[dimensionName] - cfg.arrow[dimensionName] - 2 * cfg.marginArrow;
}
/**
 * Calculates the position of the arrow on the minor axis
 * @param cfg - popup position config
 * @param dimensionName - the name of dimension (height or width)
 */
function calcArrowPosition(cfg, dimensionName) {
    return cfg.marginArrow + calcUsableSizeForArrow(cfg, dimensionName) * cfg.offsetArrowRatio;
}
/**
 * Calculates popup and arrow popup positions.
 * @param cfg - popup position config
 * */
export function calcPopupPosition(cfg) {
    return fitOnMinorAxis(cfg, fitOnMajorAxis(cfg, calcBasicPosition(cfg)));
}
