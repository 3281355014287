var _a;
import { rafDecorator } from '../async/raf';
/**
 * Small utility to provide 100vw and 100vh alternative CSS Variables
 * - vwProp (default: `--100vw`) - scroll independent viewport width value
 * - vhProp (default: `--100vh`) - device independent viewport height value (also known as 100vh iOS fix)
 */
export class ESLVSizeCSSProxy {
    /** Inits custom CSS variables for viewport sizes and it's observation */
    static observe() {
        this.update();
        window.addEventListener('resize', this.updateDebounced);
    }
    /** Destroys observer */
    static destroy() {
        window.removeEventListener('resize', this.updateDebounced);
    }
    /** Update custom CSS variables with actual viewport sizes */
    static update() {
        const $html = document.documentElement;
        $html.style.setProperty(this.vwProp, `${this.viewportWidth}px`);
        $html.style.setProperty(this.vhProp, `${this.viewportHeight}px`);
    }
    /** @returns current viewport width */
    static get viewportWidth() {
        return document.documentElement.clientWidth;
    }
    /** @returns current viewport height */
    static get viewportHeight() {
        return window.innerHeight;
    }
}
_a = ESLVSizeCSSProxy;
ESLVSizeCSSProxy.vwProp = '--100vw';
ESLVSizeCSSProxy.vhProp = '--100vh';
ESLVSizeCSSProxy.updateDebounced = rafDecorator(_a.update.bind(_a));
