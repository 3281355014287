import { identity } from './functions';
import { isArrayLike } from './object';
/** Split array into tuples */
export const tuple = (arr) => arr.reduce((acc, el) => {
    if (acc.length === 0 || acc[acc.length - 1].length >= 2)
        acc.push([]);
    acc[acc.length - 1].push(el);
    return acc;
}, []);
/**
 * Flat array - unwraps one level of nested arrays
 * @deprecated use `Array.prototype.flat` instead
 */
export const flat = (arr) => arr.flat(1);
/** Wraps passed object or primitive to array */
export const wrap = (arr) => {
    if (arr === undefined || arr === null)
        return [];
    if (Array.isArray(arr))
        return arr;
    return [arr];
};
export function unwrap(value) {
    return isArrayLike(value) ? value[0] : value;
}
/** Makes array values unique */
export const uniq = (arr) => arr.length > 1 ? [...new Set(arr)] : arr.slice(0);
export function range(n, filler = identity) {
    const arr = Array(n);
    let i = 0;
    while (i < n)
        arr[i] = filler(i++);
    return arr;
}
/**
 * @returns object with a criteria value as a key and an array of original items that belongs to the current criteria value
 */
export const groupBy = (array, group) => {
    return array.reduce((obj, el) => {
        const key = group(el);
        obj[key] ? obj[key].push(el) : obj[key] = [el];
        return obj;
    }, {});
};
