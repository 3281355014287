var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLPopup } from '../../../esl-popup/core';
import { prop, listen, memoize } from '../../../esl-utils/decorators';
import { TAB } from '../../../esl-utils/dom/keys';
import { ESLSelectList } from '../../esl-select-list/core';
/**
 * ESLSelectDropdown component
 * @author Alexey Stsefanovich (ala'n)
 *
 * Auxiliary inner custom component to render {@link ESLSelect} dropdown section
 * Uses {@link ESLSelectList} to render the content
 */
export class ESLSelectDropdown extends ESLPopup {
    static register() {
        ESLSelectList.register();
        super.register();
    }
    /** Inner ESLSelectList component */
    get $list() {
        return ESLSelectList.create();
    }
    setInitialState() { }
    connectedCallback() {
        super.connectedCallback();
        this.appendChild(this.$list);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeChild(this.$list);
    }
    onShow(params) {
        if (this.parentElement !== document.body)
            document.body.appendChild(this);
        this.$list.pinSelected = this.$owner.pinSelected;
        this.$list.selectAllLabel = this.$owner.selectAllLabel;
        this.$list.$select = this.$owner.$select;
        super.onShow(params);
        this._disposeTimeout && window.clearTimeout(this._disposeTimeout);
        const $focusable = this.querySelector('[tabindex]');
        $focusable === null || $focusable === void 0 ? void 0 : $focusable.focus({ preventScroll: true });
        this._updatePosition();
    }
    onHide(params) {
        const $select = this.activator;
        super.onHide(params);
        $select && setTimeout(() => $select.focus({ preventScroll: true }), 0);
    }
    afterOnHide(params) {
        const afterOnHideTask = () => {
            super.afterOnHide(params);
            if (this.parentElement === document.body)
                document.body.removeChild(this);
        };
        if (params.action === 'show')
            afterOnHideTask();
        else
            this._disposeTimeout = window.setTimeout(afterOnHideTask, 1000);
    }
    _onKeyboardEvent(e) {
        super._onKeyboardEvent(e);
        if (e.key === TAB)
            this._onTabKey(e);
    }
    _onTabKey(e) {
        const $els = this.querySelectorAll('[tabindex]');
        const $first = $els[0];
        const $last = $els[$els.length - 1];
        if ($first && e.target === $last && !e.shiftKey)
            $first.focus();
        if ($last && e.target === $first && e.shiftKey)
            $last.focus();
    }
    _updatePosition() {
        const $select = this.activator;
        if ($select)
            this.style.width = `${$select.getBoundingClientRect().width}px`;
        super._updatePosition();
    }
}
ESLSelectDropdown.is = 'esl-select-dropdown';
__decorate([
    prop(true)
], ESLSelectDropdown.prototype, "closeOnEsc", void 0);
__decorate([
    prop(true)
], ESLSelectDropdown.prototype, "closeOnOutsideAction", void 0);
__decorate([
    prop('bottom')
], ESLSelectDropdown.prototype, "position", void 0);
__decorate([
    memoize()
], ESLSelectDropdown.prototype, "$list", null);
__decorate([
    listen('keydown')
], ESLSelectDropdown.prototype, "_onKeyboardEvent", null);
