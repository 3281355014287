var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLMixinElement } from '@exadel/esl/modules/esl-mixin-element/ui/esl-mixin-element';
import { afterNextRender } from '@exadel/esl/modules/esl-utils/async/raf';
import { listen, memoize } from '@exadel/esl/modules/esl-utils/decorators';
import { ESLEventUtils } from '@exadel/esl/modules/esl-utils/dom/events';
/**
 * Demo sample of anchor mixin
 */
export class ESLDemoAnchorLink extends ESLMixinElement {
    get anchorHref() {
        if (this.$host instanceof HTMLAnchorElement) {
            const url = new URL(this.$host.href);
            return url.hash ? url.hash.substring(1) : null;
        }
        return null;
    }
    get anchorTarget() {
        return this.anchorHref ? document.getElementById(this.anchorHref) : null;
    }
    moveToTarget() {
        const $target = this.anchorTarget;
        if (!$target)
            return console.warn('No anchor target found');
        ESLEventUtils.dispatch($target, 'esl:show:request');
        afterNextRender(() => {
            // TODO: replace with scroll ext version
            // scrollIntoView($target, {behavior: 'smooth'})
            //   .then(() => console.log('Scroll to successful: ', $target));
            $target.scrollIntoView({ behavior: 'smooth' });
        });
    }
    onClick(e) {
        e.preventDefault();
        if (this.anchorHref) {
            history.pushState(null, '', '#' + this.anchorHref);
        }
        this.moveToTarget();
    }
}
ESLDemoAnchorLink.is = 'esl-d-anchor';
__decorate([
    memoize()
], ESLDemoAnchorLink.prototype, "anchorHref", null);
__decorate([
    memoize()
], ESLDemoAnchorLink.prototype, "anchorTarget", null);
__decorate([
    listen('click')
], ESLDemoAnchorLink.prototype, "onClick", null);
