import { ESLMediaQuery } from './esl-media-query';
/**
 * ESL Media Rule
 * @author Yuliya Adamskaya
 *
 * Helper class to wrap {@link ESLMediaQuery} with the payload value
 * @see ESLMediaQuery
 * @see ESLMediaRuleList
 */
export class ESLMediaRule {
    constructor(payload, query = '') {
        this.query = ESLMediaQuery.for(query);
        this.payload = payload;
    }
    /** @returns if the inner {@link ESLMediaQuery} is matching current device configuration */
    get matches() {
        return this.query.matches;
    }
    addEventListener(type, callback = type) {
        this.query.addEventListener(callback);
    }
    removeEventListener(type, callback = type) {
        this.query.removeEventListener(callback);
    }
    toString() {
        const val = typeof this.payload === 'object' ?
            JSON.stringify(this.payload) :
            String(this.payload);
        return `${this.query} => ${val}`;
    }
    /**
     * Creates the {@link ESLMediaRule} instance from payload string, query and valueParser.
     * If the payload parse result is undefined then rule will be undefined.
     */
    static create(payload, query, parser) {
        const payloadValue = parser(payload.trim());
        if (typeof payloadValue === 'undefined')
            return undefined;
        return new ESLMediaRule(payloadValue, query.trim());
    }
    /** Parses the rule string to the {@link ESLMediaRule} instance */
    static parse(lex, parser) {
        const parts = lex.split('=>');
        const query = parts.length === 2 ? parts[0] : '';
        const payload = parts.length === 2 ? parts[1] : parts[0];
        return ESLMediaRule.create(payload, query, parser);
    }
    /** Shortcut to create always active {@link ESLMediaRule} with passed value */
    static all(payload) {
        return new ESLMediaRule(payload, 'all');
    }
    /** Shortcut to create always inactive {@link ESLMediaRule} */
    static empty() {
        return new ESLMediaRule(undefined, 'all');
    }
}
