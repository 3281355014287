import { createDeferred } from './promise/defered';
/**
 * Creates a throttled executed function.
 * The func is invoked with the last arguments provided to the throttled function.
 * @param fn - function to decorate
 * @param threshold - indicates how often function could be called
 * @param thisArg - optional context to call original function, use debounced method call context if not defined
 */
export function throttle(fn, threshold = 250, thisArg) {
    let last;
    let timeout = null;
    let deferred = null;
    function throttledSubject(...args) {
        const now = Date.now();
        deferred = deferred || createDeferred();
        (typeof timeout === 'number') && clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            last = Date.now();
            timeout = null;
            deferred.resolve(fn.apply(thisArg || this, args));
            deferred = null;
        }, Math.max(last + threshold - now, 0));
    }
    Object.defineProperty(throttledSubject, 'promise', {
        get: () => (deferred = deferred || createDeferred()).promise
    });
    return throttledSubject;
}
