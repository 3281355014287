var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ESLToggleablePlaceholder_1;
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLBaseElement } from '../../esl-base-element/core';
let ESLToggleablePlaceholder = ESLToggleablePlaceholder_1 = class ESLToggleablePlaceholder extends ESLBaseElement {
    /** Сreates a placeholder for a given element of the toggleable's descendant */
    static from($el) {
        const $placeholder = document.createElement(this.is);
        $placeholder.$origin = $el;
        return $placeholder;
    }
    connectedCallback() {
        this.copyAttributesFromOrigin();
        super.connectedCallback();
    }
    /** Builds attribute name for copy */
    buildAttrName(name) {
        return name === 'id' ? 'original-id' : name;
    }
    /** Copies allowed attributes from origin to this element */
    copyAttributesFromOrigin() {
        const { $origin } = this;
        if (!$origin)
            return;
        this.constructor.allowedAttrs.forEach((name) => {
            const value = $origin.getAttribute(name);
            if (value) {
                this.setAttribute(this.buildAttrName(name), value);
            }
        });
        // sanitize class list of placeholder from the tag name of origin's element
        this.classList.remove($origin.constructor.is);
    }
};
ESLToggleablePlaceholder.is = 'esl-toggleable-placeholder';
/** List of attributes allowed to copy from origin to this element */
ESLToggleablePlaceholder.allowedAttrs = ['id', 'class'];
ESLToggleablePlaceholder = ESLToggleablePlaceholder_1 = __decorate([
    ExportNs('ToggleablePlaceholder')
], ESLToggleablePlaceholder);
export { ESLToggleablePlaceholder };
