const SEQUENCE_KEY = ((window.Symbol || String)('__esl_sequences__'));
const ns = window || global;
const sequences = ns[SEQUENCE_KEY] || new Map();
ns[SEQUENCE_KEY] = sequences;
/** Create and return sequential id */
export const sequentialUID = (name, prefix = name) => {
    const uid = (sequences.get(name) || 0) + 1;
    sequences.set(name, uid);
    return prefix + uid;
};
/** Return random unique identifier */
export const randUID = (prefix = '') => {
    const time = Date.now().toString(32);
    const rand = Math.round(Math.random() * 1024 * 1024).toString(32);
    return prefix + time + '-' + rand;
};
