var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLBaseElement } from '../../esl-base-element/core';
import { listen } from '../../esl-utils/decorators/listen';
import { getCompositeTarget } from '../../esl-utils/dom/events';
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLToggleable } from './esl-toggleable';
/**
 * ESLToggleableDispatcher
 * @author Julia Murashko, Alexey Stsefanovich (ala'n)
 *
 * ESLToggleableDispatcher - plugin component, that prevents activation of multiple ESLToggleable instances in bounds of managed container.
 */
let ESLToggleableDispatcher = class ESLToggleableDispatcher extends ESLBaseElement {
    constructor() {
        super(...arguments);
        this._popups = new Map();
    }
    /**
     * Initialize ToggleableGroupDispatcher
     * Uses esl-toggleable-dispatcher tag and document body root by default
     */
    static init(root = document.body, tagName = this.is) {
        if (!root)
            throw new Error('[ESL]: Root element should be specified');
        const instances = root.getElementsByTagName(tagName);
        if (instances.length)
            return;
        this.register(tagName);
        root.insertAdjacentElement('afterbegin', document.createElement(tagName));
    }
    /** Observed element */
    get root() {
        return this.parentElement;
    }
    /** Guard-condition for targets */
    isAcceptable(target) {
        if (!(target instanceof ESLToggleable))
            return false;
        return !!target.groupName && target.groupName !== 'none';
    }
    /** Hides active element in group */
    hideActive(groupName, activator) {
        const active = this.getActive(groupName);
        if (!active || active === activator)
            return;
        active.hide({
            initiator: 'dispatcher',
            dispatcher: this,
            activator
        });
    }
    /** Sets active element in group */
    setActive(groupName, popup) {
        if (!groupName)
            return;
        this.hideActive(groupName, popup);
        this._popups.set(groupName, popup);
    }
    /** Gets active element in group or undefined if group doesn't exist */
    getActive(groupName) {
        return this._popups.get(groupName);
    }
    /** Deletes element from the group if passed element is currently active */
    deleteActive(groupName, popup) {
        if (this.getActive(groupName) !== popup)
            return;
        this._popups.delete(groupName);
    }
    /** Hides active element before e.target will be shown */
    _onBeforeShow(e) {
        const target = getCompositeTarget(e);
        if (!this.isAcceptable(target))
            return;
        this.hideActive(target.groupName, target);
    }
    /** Updates active element after a new element is shown */
    _onShow(e) {
        const target = getCompositeTarget(e);
        if (!this.isAcceptable(target))
            return;
        this.setActive(target.groupName, target);
    }
    /** Updates group state after active element is hidden */
    _onHide(e) {
        const target = getCompositeTarget(e);
        if (!this.isAcceptable(target))
            return;
        this.deleteActive(target.groupName, target);
    }
    /** Updates active elements */
    _onChangeGroup(e) {
        const target = getCompositeTarget(e);
        if (!this.isAcceptable(target))
            return;
        const { oldGroupName, newGroupName } = e.detail;
        this.deleteActive(oldGroupName, target);
        this.setActive(newGroupName, target);
    }
};
ESLToggleableDispatcher.is = 'esl-toggleable-dispatcher';
__decorate([
    listen({
        event: ESLToggleable.prototype.BEFORE_SHOW_EVENT,
        target: (host) => host.root
    })
], ESLToggleableDispatcher.prototype, "_onBeforeShow", null);
__decorate([
    listen({
        event: ESLToggleable.prototype.SHOW_EVENT,
        target: (host) => host.root
    })
], ESLToggleableDispatcher.prototype, "_onShow", null);
__decorate([
    listen({
        event: ESLToggleable.prototype.HIDE_EVENT,
        target: (host) => host.root
    })
], ESLToggleableDispatcher.prototype, "_onHide", null);
__decorate([
    listen({
        event: ESLToggleable.prototype.GROUP_CHANGED_EVENT,
        target: (host) => host.root
    })
], ESLToggleableDispatcher.prototype, "_onChangeGroup", null);
ESLToggleableDispatcher = __decorate([
    ExportNs('ToggleableDispatcher')
], ESLToggleableDispatcher);
export { ESLToggleableDispatcher };
