var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLBaseElement, ESLIntersectionTarget, listen, memoize } from '@exadel/esl';
export class ESLDemoMarqueeOwl extends ESLBaseElement {
    constructor() {
        super(...arguments);
        this._timeout = 0;
    }
    get $text() {
        return [...this.querySelectorAll('text')];
    }
    get text() {
        return this.$text.map(($line) => $line.textContent || '').join(' ');
    }
    set text(text) {
        const words = text.split(' ').reverse();
        for (const $line of this.$text) {
            let line = words.pop() || '';
            while (words.length && (line.length + words.at(-1).length < ESLDemoMarqueeOwl.MAX_LINES_LENGTH)) {
                line += ` ${words.pop()}`;
            }
            $line.textContent = line;
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.text = ESLDemoMarqueeOwl.INITIAL_PHRASE;
    }
    // Activator of interactive actions
    _onIntersectIn() {
        this._timeout = window.setTimeout(() => this.$$attr('active', true), ESLDemoMarqueeOwl.INITIAL_TIMEOUT);
    }
    _onIntersectOut() {
        window.clearTimeout(this._timeout);
    }
    // Click actions
    _onClick() {
        this.text = ESLDemoMarqueeOwl.PHRASES[Math.floor(ESLDemoMarqueeOwl.PHRASES.length * Math.random())];
        clearTimeout(this._timeout);
        this._timeout = window.setTimeout(() => this.text = ESLDemoMarqueeOwl.INITIAL_PHRASE, ESLDemoMarqueeOwl.RESET_TIMEOUT);
    }
}
ESLDemoMarqueeOwl.is = 'esl-d-marquee-owl';
ESLDemoMarqueeOwl.INITIAL_TIMEOUT = 4000;
ESLDemoMarqueeOwl.RESET_TIMEOUT = 4000;
ESLDemoMarqueeOwl.MAX_LINES_LENGTH = 16;
ESLDemoMarqueeOwl.INITIAL_PHRASE = 'Did you come to contribute?';
ESLDemoMarqueeOwl.PHRASES = [
    'Yes...',
    'What\'s up?',
    'What do you want?',
    'What?',
    'I am not going to fly away...',
    'Whoo, whoo, whoo!!!',
    'Well, let\'s say Caw, caw, caw!'
];
__decorate([
    memoize()
], ESLDemoMarqueeOwl.prototype, "$text", null);
__decorate([
    listen({
        event: 'intersects:in',
        target: ESLIntersectionTarget.for,
        once: true
    })
], ESLDemoMarqueeOwl.prototype, "_onIntersectIn", null);
__decorate([
    listen({
        event: 'intersects:out',
        target: ESLIntersectionTarget.for,
        once: true
    })
], ESLDemoMarqueeOwl.prototype, "_onIntersectOut", null);
__decorate([
    listen({
        event: 'click',
        target: '::find(image)',
    })
], ESLDemoMarqueeOwl.prototype, "_onClick", null);
