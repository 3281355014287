import { overrideEvent } from '../../esl-utils/dom/events/misc';
/**
 * An event dispatched by {@link ESLMediaProviderRegistry} on new provider registration
 */
export class ESLMediaRegistryEvent extends Event {
    constructor(target, provider) {
        super('change', { bubbles: false, cancelable: false });
        this.provider = provider;
        overrideEvent(this, 'target', target);
    }
    /** Checks if the event relates to passed provider name */
    isRelates(name) {
        var _a;
        name = name.toLowerCase() || 'auto';
        return name === 'auto' || ((_a = this.provider) === null || _a === void 0 ? void 0 : _a.providerName) === name;
    }
}
