/** Converts notes list to footnotes items list */
function convertNotesToFootnotesList(notes) {
    return notes.map(({ index, renderedIndex, html }) => ({
        index: [`${index}`],
        renderedIndex: [renderedIndex],
        text: html
    }));
}
/** Compiles footnotes non-grouped list */
export function compileFootnotesNongroupedList(notes) {
    return convertNotesToFootnotesList(notes.filter((note) => note.allowFootnotes));
}
/** Compiles footnotes grouped list */
export function compileFootnotesGroupedList(notes) {
    const map = new Map();
    convertNotesToFootnotesList(notes.filter((note) => note.allowFootnotes)).forEach((note) => {
        const { index, renderedIndex, text } = note;
        map.has(text) ? map.set(text, {
            index: [...map.get(text).index, ...index],
            renderedIndex: [...map.get(text).renderedIndex, ...renderedIndex],
            text
        }) : map.set(text, note);
    });
    const groupedList = [];
    map.forEach((note, text) => groupedList.push(note));
    return groupedList;
}
/** Sorts notes list */
export function sortFootnotes(notes) {
    return notes.sort((note1, note2) => {
        if (note1 === note2)
            return 0;
        // eslint-disable-next-line no-bitwise
        if (note1.compareDocumentPosition(note2) & Node.DOCUMENT_POSITION_PRECEDING) {
            // note2 comes before note1
            return 1;
        }
        return -1;
    });
}
