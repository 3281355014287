/** Custom event dispatched by {@link ESLMediaQuery} instances */
export class ESLMediaChangeEvent extends Event {
    constructor(matches) {
        super('change');
        this.matches = matches;
    }
    /** Returns serialized value of the current {@link ESLMediaQuery} */
    get media() {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        return String(this.target);
    }
}
