var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { boolAttr, listen, prop, ready } from '@exadel/esl/modules/esl-utils/decorators';
import { ESLToggleable } from '@exadel/esl/modules/esl-toggleable/core/esl-toggleable';
import { ESLMediaQuery } from '@exadel/esl/modules/esl-media-query/core/esl-media-query';
export class ESLDemoSidebar extends ESLToggleable {
    constructor() {
        super(...arguments);
        this.closeOnEsc = true;
        this.closeOnOutsideAction = true;
        this.submenus = '.sidebar-nav-secondary';
        this.activeMenuAttr = 'data-open';
    }
    get $submenus() {
        return Array.from(this.querySelectorAll(this.submenus));
    }
    connectedCallback() {
        super.connectedCallback();
    }
    storeState() {
        this.open ? localStorage.removeItem('sidebar-collapsed') : localStorage.setItem('sidebar-collapsed', 'true');
    }
    setInitialState() {
        const isDesktop = ESLMediaQuery.for('@+MD').matches;
        const isStoredOpen = !localStorage.getItem('sidebar-collapsed');
        this.toggle(isDesktop && isStoredOpen, { force: true, initiator: 'init', immediate: true });
    }
    collapseAll() {
        this.$submenus.forEach((menu) => menu.hide({ activator: this }));
    }
    expandActive(noAnimate = false) {
        this.$submenus
            .filter((menu) => menu.hasAttribute('data-open'))
            .forEach((menu) => menu.show({ noAnimate, activator: this }));
    }
    updateA11y() {
        const targetEl = this.$a11yTarget;
        if (!targetEl)
            return;
        targetEl.setAttribute('aria-expanded', String(this.open));
    }
    onShow(params) {
        this._animation = !params.immediate;
        super.onShow(params);
        this.expandActive(params.initiator === 'init');
        if (params.activator && params.activator.hasAttribute('data-store')) {
            this.storeState();
        }
    }
    onHide(params) {
        this._animation = !params.immediate;
        super.onHide(params);
        this.collapseAll();
        if (params.activator && params.activator.hasAttribute('data-store')) {
            this.storeState();
        }
    }
    onBreakpointChange({ matches: isDesktop }) {
        const isStoredOpen = !localStorage.getItem('sidebar-collapsed');
        this.toggle(isDesktop && isStoredOpen, { force: true, initiator: 'bpchange', immediate: !isDesktop });
    }
    _onOutsideAction(e) {
        if (ESLMediaQuery.for('@+MD').matches)
            return;
        super._onOutsideAction(e);
    }
}
ESLDemoSidebar.is = 'esl-d-sidebar';
__decorate([
    prop()
], ESLDemoSidebar.prototype, "closeOnEsc", void 0);
__decorate([
    prop()
], ESLDemoSidebar.prototype, "closeOnOutsideAction", void 0);
__decorate([
    prop()
], ESLDemoSidebar.prototype, "submenus", void 0);
__decorate([
    prop()
], ESLDemoSidebar.prototype, "activeMenuAttr", void 0);
__decorate([
    boolAttr({ name: 'animation' })
], ESLDemoSidebar.prototype, "_animation", void 0);
__decorate([
    ready
], ESLDemoSidebar.prototype, "connectedCallback", null);
__decorate([
    listen({
        event: 'change',
        target: ESLMediaQuery.for('@+MD')
    })
], ESLDemoSidebar.prototype, "onBreakpointChange", null);
__decorate([
    listen({ inherit: true })
], ESLDemoSidebar.prototype, "_onOutsideAction", null);
