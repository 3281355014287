/**
 * A decorator utility to postpone callback execution once after the main task execution
 * (as a microtask produced with Promise)
 */
export function microtask(fn, thisArg) {
    let args = [];
    return function microtaskFn(arg) {
        args.push(arg);
        if (microtaskFn.request)
            return;
        microtaskFn.request = Promise.resolve().then(() => {
            delete microtaskFn.request;
            fn.call(thisArg || this, args);
            args = [];
        });
    };
}
