var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLBaseElement } from '@exadel/esl/modules/esl-base-element/core';
import { ESLPopup } from '@exadel/esl/modules/esl-popup/core';
import { attr, boolAttr, listen, memoize } from '@exadel/esl/modules/esl-utils/decorators';
import { ESLResizeObserverTarget } from '@exadel/esl/modules/esl-utils/dom';
import { ESLTraversingQuery } from '@exadel/esl/modules/esl-traversing-query/core';
/** Fun component like a game for checking popup positioning on edge cases */
export class ESLDemoPopupGame extends ESLBaseElement {
    constructor() {
        super(...arguments);
        /** Marker shows that element is in dragging mode */
        this.dragging = false;
    }
    get $trigger() {
        if (this.trigger)
            return ESLTraversingQuery.first(this.trigger, this);
    }
    connectedCallback() {
        this.setTriggerDefaultPosition();
        super.connectedCallback();
    }
    setTriggerDefaultPosition() {
        const center = this.calculateCenter();
        this.updateTriggerPosition(center.x, center.y);
    }
    calculateCenter() {
        return { x: this.clientWidth / 2, y: this.clientHeight / 2 };
    }
    _onDragStart(event) {
        this.dragging = true;
        this.$$on(this._onDragging);
        this.$$on(this._onDragStop);
        event.preventDefault();
        this.currentCoords = { x: event.clientX, y: event.clientY };
    }
    _onDragging(event) {
        if (!this.dragging || !this.$trigger)
            return;
        const { clientX, clientY } = event;
        const dX = clientX - this.currentCoords.x;
        const dY = clientY - this.currentCoords.y;
        this.currentCoords = { x: clientX, y: clientY };
        this.updateTriggerPosition(dX, dY);
        event.preventDefault();
    }
    updateTriggerPosition(dX, dY) {
        if (!this.$trigger)
            return;
        this.$$fire(ESLPopup.prototype.REFRESH_EVENT);
        const limitX = this.clientWidth - this.$trigger.clientWidth;
        const limitY = this.clientHeight - this.$trigger.clientHeight;
        let x = (parseInt(this.$trigger.style.left, 10) || 0) + dX;
        let y = (parseInt(this.$trigger.style.top, 10) || 0) + dY;
        x = Math.max(0, Math.min(x, limitX));
        y = Math.max(0, Math.min(y, limitY));
        this.$trigger.style.left = `${x}px`;
        this.$trigger.style.top = `${y}px`;
    }
    _onDragStop() {
        this.dragging = false;
        this.$$off(this._onDragging);
        this.$$off(this._onDragStop);
    }
    _onResize() {
        this.updateTriggerPosition(0, 0);
    }
}
ESLDemoPopupGame.is = 'esl-d-popup-game';
__decorate([
    attr()
], ESLDemoPopupGame.prototype, "trigger", void 0);
__decorate([
    boolAttr()
], ESLDemoPopupGame.prototype, "dragging", void 0);
__decorate([
    memoize()
], ESLDemoPopupGame.prototype, "$trigger", null);
__decorate([
    listen({ event: 'mousedown', target: ($this) => $this.$trigger })
], ESLDemoPopupGame.prototype, "_onDragStart", null);
__decorate([
    listen({ auto: false, event: 'mousemove', target: window })
], ESLDemoPopupGame.prototype, "_onDragging", null);
__decorate([
    listen({ auto: false, event: 'mouseup', target: window })
], ESLDemoPopupGame.prototype, "_onDragStop", null);
__decorate([
    listen({ event: 'resize', target: ESLResizeObserverTarget.for })
], ESLDemoPopupGame.prototype, "_onResize", null);
