var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../../esl-utils/environment/export-ns';
import { attr, listen, memoize, ready } from '../../../esl-utils/decorators';
import { ESLMixinElement } from '../../../esl-mixin-element/core';
import { ESLTraversingQuery } from '../../../esl-traversing-query/core';
import { ESLCarouselChangeEvent, ESLCarouselSlideEvent } from '../../core/esl-carousel.events';
/**
 * ESLCarousel navigation helper to define triggers for carousel navigation.
 *
 * Example:
 * ```
 * <div class="esl-carousel-nav-container">
 *  <button esl-carousel-nav="group:prev">Prev</button>
 *  <esl-carousel>...</esl-carousel>
 *  <button esl-carousel-nav="group:next">Next</button>
 * </div>
 * ```
 */
let ESLCarouselNavMixin = class ESLCarouselNavMixin extends ESLMixinElement {
    /** @returns ESLCarousel instance; based on {@link carousel} attribute */
    get $carousel() {
        return ESLTraversingQuery.first(this.carousel, this.$host);
    }
    /** @returns accessible target ID */
    get targetID() {
        var _a;
        return ((_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.id) || '';
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.$carousel)
            return;
        if (this.$carousel.renderer)
            this._onUpdate();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        memoize.clear(this, '$carousel');
        this.$$attr('active', false);
        this.$$attr('disabled', false);
    }
    /** Handles carousel state changes */
    _onUpdate() {
        var _a;
        const isActive = !!((_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.renderer) && !this.$carousel.incomplete;
        const isDisabled = isActive && !this.$carousel.canNavigate(this.command);
        this.$$attr('active', isActive);
        this.$$attr('disabled', isDisabled);
        this.$$attr('aria-controls', this.targetID);
    }
    /** Handles $host element click */
    _onClick(e) {
        if (!this.$carousel || typeof this.$carousel.goTo !== 'function')
            return;
        this.$carousel.goTo(this.command).catch(console.error);
        e.preventDefault();
    }
};
ESLCarouselNavMixin.is = 'esl-carousel-nav';
__decorate([
    attr({ name: ESLCarouselNavMixin.is })
], ESLCarouselNavMixin.prototype, "command", void 0);
__decorate([
    attr({
        name: ESLCarouselNavMixin.is + '-target',
        defaultValue: '::parent(.esl-carousel-nav-container)::find(esl-carousel)'
    })
], ESLCarouselNavMixin.prototype, "carousel", void 0);
__decorate([
    memoize()
], ESLCarouselNavMixin.prototype, "$carousel", null);
__decorate([
    ready
], ESLCarouselNavMixin.prototype, "connectedCallback", null);
__decorate([
    listen({
        event: `${ESLCarouselChangeEvent.TYPE} ${ESLCarouselSlideEvent.AFTER}`,
        target: ($nav) => $nav.$carousel
    })
], ESLCarouselNavMixin.prototype, "_onUpdate", null);
__decorate([
    listen('click')
], ESLCarouselNavMixin.prototype, "_onClick", null);
ESLCarouselNavMixin = __decorate([
    ExportNs('Carousel.Nav')
], ESLCarouselNavMixin);
export { ESLCarouselNavMixin };
