var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { attr, boolAttr, listen } from '../../../esl-utils/decorators';
import { CSSClassUtils } from '../../../esl-utils/dom/class';
import { ENTER, SPACE } from '../../../esl-utils/dom/keys';
import { ExportNs } from '../../../esl-utils/environment/export-ns';
import { ESLEventUtils } from '../../../esl-utils/dom/events';
import { ESLSelectWrapper } from '../../esl-select-list/core/esl-select-wrapper';
import { ESLSelectRenderer } from './esl-select-renderer';
import { ESLSelectDropdown } from './esl-select-dropdown';
/**
 * ESLSelect component
 * @author Alexey Stsefanovich (ala'n)
 *
 * ESLSelect is a component on top of native select that brings more customization features.
 * Uses "select with dropdown" view. Supports both single and multiple selection.
 */
let ESLSelect = class ESLSelect extends ESLSelectWrapper {
    static register() {
        ESLSelectDropdown.register();
        ESLSelectRenderer.register();
        super.register();
    }
    constructor() {
        super();
        this.$renderer = document.createElement(ESLSelectRenderer.is);
        this.$dropdown = document.createElement(ESLSelectDropdown.is);
    }
    attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === 'disabled')
            this._updateDisabled();
        if (attrName === 'dropdown-class') {
            this.$dropdown.$$cls(oldVal, false);
            this.$dropdown.$$cls(newVal, true);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.$select = this.querySelector('[esl-select-target]');
        if (!this.$select)
            return;
        this._prepare();
        this._updateDisabled();
        this._updateMarkers();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this._dispose();
    }
    /** Catches the focus */
    focus(options) {
        this.$select.focus(options);
    }
    /** Updates select component */
    update(valueChanged = true) {
        this._updateMarkers();
        if (!valueChanged)
            return;
        // TODO: silent updates
        ESLEventUtils.dispatch(this, 'esl:change:value', { detail: { event: null } });
    }
    _prepare() {
        this.$renderer.className = this.$select.className;
        this.$renderer.emptyText = this.placeholder;
        this.$renderer.moreLabelFormat = this.moreLabelFormat;
        this.$dropdown.$owner = this;
        this.appendChild(this.$renderer);
    }
    _dispose() {
        this.$select.className = this.$renderer.className;
        this.removeChild(this.$renderer);
    }
    _updateMarkers() {
        const hasValue = this.hasSelected();
        this.toggleAttribute('has-value', hasValue);
        CSSClassUtils.toggle(this, this.hasValueClass, hasValue);
        const focusEl = document.activeElement;
        const hasFocus = this.open || (focusEl && this.contains(focusEl));
        CSSClassUtils.toggle(this, this.hasFocusClass, !!hasFocus);
    }
    _updateDisabled() {
        this.setAttribute('aria-disabled', String(this.disabled));
        if (!this.$select)
            return;
        this.$select.disabled = this.disabled;
        if (this.disabled && this.open)
            this.$dropdown.hide();
    }
    _onChange(event) {
        this._updateMarkers();
        ESLEventUtils.dispatch(this, 'esl:change:value', { detail: { event } });
    }
    _onFocusOut() {
        this._updateMarkers();
    }
    _onClick() {
        if (this.disabled)
            return;
        this.$dropdown.toggle(!this.$dropdown.open, {
            activator: this.$renderer,
            initiator: 'select',
            extraClass: this.dropdownClass
        });
    }
    _onKeydown(e) {
        if ([ENTER, SPACE].includes(e.key)) {
            this.click();
            e.preventDefault();
        }
    }
    _onPopupStateChange(e) {
        this.open = this.$dropdown.open;
        this._updateMarkers();
    }
};
ESLSelect.is = 'esl-select';
ESLSelect.observedAttributes = ['disabled', 'dropdown-class'];
__decorate([
    attr()
], ESLSelect.prototype, "placeholder", void 0);
__decorate([
    attr()
], ESLSelect.prototype, "hasValueClass", void 0);
__decorate([
    attr()
], ESLSelect.prototype, "hasFocusClass", void 0);
__decorate([
    attr()
], ESLSelect.prototype, "dropdownClass", void 0);
__decorate([
    attr({ defaultValue: 'Select All' })
], ESLSelect.prototype, "selectAllLabel", void 0);
__decorate([
    attr({ defaultValue: '+ {rest} more...' })
], ESLSelect.prototype, "moreLabelFormat", void 0);
__decorate([
    boolAttr()
], ESLSelect.prototype, "open", void 0);
__decorate([
    boolAttr()
], ESLSelect.prototype, "disabled", void 0);
__decorate([
    boolAttr()
], ESLSelect.prototype, "pinSelected", void 0);
__decorate([
    listen({ inherit: true })
], ESLSelect.prototype, "_onChange", null);
__decorate([
    listen('focusout')
], ESLSelect.prototype, "_onFocusOut", null);
__decorate([
    listen('click')
], ESLSelect.prototype, "_onClick", null);
__decorate([
    listen('keydown')
], ESLSelect.prototype, "_onKeydown", null);
__decorate([
    listen({
        event: 'esl:show esl:hide',
        target: (el) => el.$dropdown
    })
], ESLSelect.prototype, "_onPopupStateChange", null);
ESLSelect = __decorate([
    ExportNs('Select')
], ESLSelect);
export { ESLSelect };
