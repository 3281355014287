var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ready, memoize, attr, boolAttr } from '../../esl-utils/decorators';
import { ESLTraversingQuery } from '../../esl-traversing-query/core';
import { parseNumber } from '../../esl-utils/misc/format';
import { ESLBaseElement } from '../../esl-base-element/core';
import { ESLAnimateService } from './esl-animate-service';
/**
 * ESLAnimate - custom element for quick {@link ESLAnimateService} attaching
 *
 * Have two types of usage:
 * - trough the target-s definition, then esl-animate (without it's own content) became invisible plugin-component
 * `<esl-animate target="::next"></esl-animate><div>Content</div>`
 * - trough the content wrapping
 * `<esl-animate>Content</esl-animate>`
 */
let ESLAnimate = class ESLAnimate extends ESLBaseElement {
    /** Elements-targets found by target query */
    get $targets() {
        return ESLTraversingQuery.all(this.target, this);
    }
    attributeChangedCallback() {
        if (!this.connected)
            return;
        this.reanimate();
    }
    connectedCallback() {
        super.connectedCallback();
        this.reanimate();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        ESLAnimateService.unobserve(this.$targets);
    }
    /** Reinitialize {@link ESLAnimateService} for targets */
    reanimate() {
        ESLAnimateService.unobserve(this.$targets);
        memoize.clear(this, '$targets');
        ESLAnimateService.observe(this.$targets, {
            force: true,
            cls: this.cls,
            ratio: parseNumber(this.ratio, 0.4),
            repeat: this.repeat,
            group: this.group,
            groupDelay: parseNumber(this.groupDelay, 0)
        });
    }
};
ESLAnimate.is = 'esl-animate';
ESLAnimate.observedAttributes = ['group', 'repeat', 'target'];
__decorate([
    attr({ defaultValue: 'in' })
], ESLAnimate.prototype, "cls", void 0);
__decorate([
    boolAttr()
], ESLAnimate.prototype, "group", void 0);
__decorate([
    attr({ defaultValue: '100' })
], ESLAnimate.prototype, "groupDelay", void 0);
__decorate([
    boolAttr()
], ESLAnimate.prototype, "repeat", void 0);
__decorate([
    attr()
], ESLAnimate.prototype, "ratio", void 0);
__decorate([
    attr()
], ESLAnimate.prototype, "target", void 0);
__decorate([
    memoize()
], ESLAnimate.prototype, "$targets", null);
__decorate([
    ready
], ESLAnimate.prototype, "connectedCallback", null);
__decorate([
    ready
], ESLAnimate.prototype, "disconnectedCallback", null);
ESLAnimate = __decorate([
    ExportNs('Animate')
], ESLAnimate);
export { ESLAnimate };
