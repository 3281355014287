var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLMixinElement } from '../../esl-mixin-element/core';
import { prop } from '../../esl-utils/decorators';
/**
 * Optional mixin element to support esl-note-ignore attribute dynamic changes
 */
export class ESLNoteIgnore extends ESLMixinElement {
    /** Callback to handle changing of additional attributes */
    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue)
            return;
        [...this.$host.querySelectorAll(this.noteSelector)].forEach(($note) => $note.updateIgnoredQuery());
    }
}
ESLNoteIgnore.is = 'esl-note-ignore';
__decorate([
    prop('esl-note')
], ESLNoteIgnore.prototype, "noteSelector", void 0);
