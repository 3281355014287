var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ESLSelectItem_1;
import { ESLBaseElement } from '../../../esl-base-element/core';
import { attr, boolAttr } from '../../../esl-utils/decorators';
import { ExportNs } from '../../../esl-utils/environment/export-ns';
/**
 * ESLSelectItem component
 * @author Alexey Stsefanovich (ala'n)
 *
 * ESLSelectItem - inner component to render an option
 */
let ESLSelectItem = ESLSelectItem_1 = class ESLSelectItem extends ESLBaseElement {
    connectedCallback() {
        super.connectedCallback();
        this.tabIndex = 0;
        this.setAttribute('role', 'checkbox');
        this.setAttribute('aria-selected', String(this.selected));
    }
    attributeChangedCallback(attrName) {
        if (attrName === 'selected') {
            this.setAttribute('aria-selected', String(this.selected));
        }
        if (attrName === 'disabled') {
            this.setAttribute('aria-disabled', String(this.disabled));
        }
    }
    update(option) {
        if (option)
            this.original = option;
        this.value = this.original.value;
        this.disabled = this.original.disabled;
        this.selected = this.original.selected;
        this.textContent = this.original.text;
    }
    /** Helper to create an option item */
    static build(option) {
        const item = ESLSelectItem_1.create();
        item.update(option);
        return item;
    }
};
ESLSelectItem.is = 'esl-select-item';
ESLSelectItem.observedAttributes = ['selected', 'disabled'];
__decorate([
    attr()
], ESLSelectItem.prototype, "value", void 0);
__decorate([
    boolAttr()
], ESLSelectItem.prototype, "selected", void 0);
__decorate([
    boolAttr()
], ESLSelectItem.prototype, "disabled", void 0);
ESLSelectItem = ESLSelectItem_1 = __decorate([
    ExportNs('SelectItem')
], ESLSelectItem);
export { ESLSelectItem };
