var _a;
import { SyntheticEventTarget } from '../../../esl-utils/dom/events/target';
import { isElement } from '../../../esl-utils/dom/api';
import { resolveDomTarget } from '../../../esl-utils/abstract/dom-target';
import { ESLElementResizeEvent } from './resize.target.event';
export { ESLElementResizeEvent };
/** Adapter class for {@link ResizeObserver} that implements {@link EventTarget} */
export class ESLResizeObserverTarget extends SyntheticEventTarget {
    /** Internal method to handle {@link ResizeObserver} entry change */
    static handleChange(entry) {
        const adapter = this.mapping.get(entry.target);
        if (!adapter)
            return;
        adapter.dispatchEvent(ESLElementResizeEvent.fromEntry(entry));
    }
    static for(target) {
        const $target = resolveDomTarget(target);
        if (isElement($target))
            return new _a($target);
        // Error handling
        console.warn('[ESL]: ESLResizeObserverTarget can not observe %o', target);
        return null;
    }
    /**
     * Creates {@link ESLResizeObserverTarget} for the {@link ESLDomElementTarget}.
     * Note the {@link ESLResizeObserverTarget} instances are singletons relatively to the {@link Element}
     */
    constructor(target) {
        const instance = _a.mapping.get(target);
        if (instance)
            return instance;
        super();
        this.target = target;
        _a.mapping.set(this.target, this);
    }
    addEventListener(event, callback = event) {
        if (typeof event === 'string' && event !== 'resize') {
            console.warn(`[ESL]: ESLResizeObserverTarget does not support '${event}' type`);
            return;
        }
        super.addEventListener('resize', callback);
        if (this.getEventListeners('resize').length > 1)
            return;
        _a.observer$$.observe(this.target);
    }
    removeEventListener(event, callback = event) {
        if (typeof event === 'string' && event !== 'resize')
            return;
        super.removeEventListener('resize', callback);
        if (this.hasEventListener('resize'))
            return;
        _a.observer$$.unobserve(this.target);
    }
}
_a = ESLResizeObserverTarget;
/** {@link ESLResizeObserverTarget} instances holder */
ESLResizeObserverTarget.mapping = new WeakMap();
/** {@link ResizeObserver} instance to observe DOM element related to {@link ESLResizeObserverTarget} */
ESLResizeObserverTarget.observer$$ = new ResizeObserver((changes) => changes.forEach(_a.handleChange, _a));
