var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from './export-ns';
const { userAgent, vendor, platform } = window.navigator;
// IE Detection
export const isTrident = /trident/i.test(userAgent);
export const isIE = isTrident;
// Edge Detection
export const isEdgeHTML = /edg([ea]|ios)/i.test(userAgent);
export const isBlinkEdge = /\sedg\//i.test(userAgent);
export const isEdge = isEdgeHTML || isBlinkEdge;
// Gecko
export const isGecko = /gecko/i.test(userAgent) && !/like gecko/i.test(userAgent);
export const isFirefox = /firefox|iceweasel|fxios/i.test(userAgent);
// Opera / Chrome
export const isOpera = /(?:^opera.+?version|opr)/.test(userAgent);
export const isChrome = !isOpera && /google inc/.test(vendor);
// Webkit
export const isWebkit = /(apple)?webkit/i.test(userAgent);
// Safari
export const isSafari = isWebkit && /^((?!chrome|android).)*safari/i.test(userAgent);
// Blink
export const isBlink = isWebkit && !isSafari;
// Bot detection
export const isBot = /Chrome-Lighthouse|Google Page Speed Insights/i.test(userAgent);
// Mobile
export const isAndroid = /Android/i.test(userAgent);
export const isMobileIOS13 = /* iOS 13+ detection */ (platform === 'MacIntel' && window.navigator.maxTouchPoints > 1);
export const isMobileIOS = /iPad|iPhone|iPod/.test(platform) || isMobileIOS13;
export const isLegacyMobile = /webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const isMobile = isMobileIOS || isAndroid || isLegacyMobile;
export const isMobileSafari = isMobileIOS && isWebkit && /CriOS/i.test(userAgent);
// Touch Detection
export const isTouchDevice = (() => {
    const navApi = window.navigator;
    if (navApi.maxTouchPoints || navApi.msMaxTouchPoints)
        return true;
    return ('ontouchstart' in window) || ('DocumentTouch' in window && document instanceof Touch);
})();
// Hover check
// Note: always true for IE
export const hasHover = !matchMedia('(hover: none)').matches;
/** true if a user prefers to minimize the amount of non-essential motion */
export const isReducedMotion = matchMedia('(prefers-reduced-motion: reduce)').matches;
/**
 * Device detection utility
 * @readonly
 * @deprecated use separate checks from the same module instead
 */
let DeviceDetector = class DeviceDetector {
};
// IE Detection
DeviceDetector.isTrident = isTrident;
DeviceDetector.isIE = isIE;
// Edge Detection
DeviceDetector.isEdgeHTML = isEdgeHTML;
DeviceDetector.isBlinkEdge = isBlinkEdge;
DeviceDetector.isEdge = isEdge;
// Gecko
DeviceDetector.isGecko = isGecko;
DeviceDetector.isFirefox = isFirefox;
// Opera / Chrome
DeviceDetector.isOpera = isOpera;
DeviceDetector.isChrome = isChrome;
// Webkit
DeviceDetector.isWebkit = isWebkit;
// Safari
DeviceDetector.isSafari = isSafari;
// Blink
DeviceDetector.isBlink = isBlink;
// Bot detection
DeviceDetector.isBot = isBot;
// Mobile
DeviceDetector.isAndroid = isAndroid;
DeviceDetector.isMobileIOS13 = isMobileIOS13;
DeviceDetector.isMobileIOS = isMobileIOS;
DeviceDetector.isLegacyMobile = isLegacyMobile;
DeviceDetector.isMobile = isMobile;
DeviceDetector.isMobileSafari = isMobileSafari;
// Touch Detection
DeviceDetector.isTouchDevice = isTouchDevice;
// Hover check
// Note: always true for IE
DeviceDetector.hasHover = hasHover;
DeviceDetector = __decorate([
    ExportNs('DeviceDetector')
], DeviceDetector);
export { DeviceDetector };
