/**
 * Const media condition implementation
 * @author Alexey Stsefanovich (ala'n)
 *
 * Ignores listeners always return the same result.
 * Have only two instances: {@link ALL} and {@link NOT_ALL}
 */
class MediaQueryConstCondition {
    constructor(_matches) {
        this._matches = _matches;
    }
    get matches() {
        return this._matches;
    }
    addEventListener() { }
    removeEventListener() { }
    dispatchEvent() {
        return false;
    }
    optimize() {
        return this;
    }
    toString() {
        return this._matches ? 'all' : 'not all';
    }
    /** Compares const media condition with the passed query instance or string */
    eq(val) {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        return String(val).trim() === this.toString();
    }
}
export const ALL = new MediaQueryConstCondition(true);
export const NOT_ALL = new MediaQueryConstCondition(false);
