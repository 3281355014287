import { ESLEventUtils } from '../../esl-event-listener/core';
export function listen(desc) {
    return function listener(target, propertyKey) {
        // Map short event or event provider value to descriptor object
        desc = typeof desc === 'string' || typeof desc === 'function' ? { event: desc } : desc;
        // Makes auto collectable/subscribable description if not inherited
        desc = Object.assign(desc.inherit ? {} : { auto: true }, desc);
        ESLEventUtils.initDescriptor(target, propertyKey, desc);
    };
}
