import { onDocumentReady } from '../dom/ready';
/** Defer method execution to the next task with dom ready state precondition */
export function ready(target, propertyKey, descriptor) {
    if (!descriptor || typeof descriptor.value !== 'function') {
        throw new TypeError('Only class methods can be decorated via document ready decorator');
    }
    const fn = descriptor.value;
    descriptor.value = function (...arg) {
        onDocumentReady(() => fn.call(this, ...arg));
    };
}
