import { createDeferred } from './promise/defered';
/**
 * Creates a debounced function that implements {@link Debounced}.
 * Debounced function delays invoking func until after wait milliseconds have elapsed
 * since the last time the debounced function was invoked.
 * The func is invoked with the last arguments provided to the debounced function.
 * @param fn - function to decorate
 * @param wait - time to debounce
 * @param thisArg - optional context to call original function, use debounced method call context if not defined
 */
export function debounce(fn, wait = 10, thisArg) {
    let timeout = null;
    let deferred = null;
    function debouncedSubject(...args) {
        deferred = deferred || createDeferred();
        (typeof timeout === 'number') && clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            timeout = null;
            // fn.apply to save call context
            const result = fn.apply(thisArg || this, args);
            deferred && deferred.resolve(result);
            deferred = null;
        }, wait);
    }
    function cancel() {
        (typeof timeout === 'number') && clearTimeout(timeout);
        timeout = null;
        deferred === null || deferred === void 0 ? void 0 : deferred.reject();
        deferred = null;
    }
    Object.defineProperty(debouncedSubject, 'promise', {
        get: () => deferred ? deferred.promise : Promise.resolve()
    });
    Object.defineProperty(debouncedSubject, 'cancel', {
        writable: false,
        enumerable: false,
        value: cancel
    });
    return debouncedSubject;
}
