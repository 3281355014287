/**
 * Memoization decorator function. Caches the original function result according to hash generated from arguments.
 * In case the hash function returns `undefined` value will not be memoized.
 * @see MemoHashFn Hash function signature.
 */
export function memoizeFn(fn, hashFn = defaultArgsHashFn) {
    function memo(...args) {
        const key = hashFn(...args);
        if (key !== null && typeof key !== 'string') {
            console.warn(`[ESL]: Can't cache value for ${fn.name} call.`);
            return fn.apply(this, args);
        }
        if (!memo.cache.has(key)) {
            memo.cache.set(key, fn.apply(this, args));
        }
        return memo.cache.get(key);
    }
    memo.cache = new Map();
    memo.clear = () => memo.cache.clear();
    memo.has = (...args) => {
        const key = hashFn(...args);
        return key === undefined ? false : memo.cache.has(key);
    };
    return memo;
}
/**
 * Default arguments hash function.
 * Supports only 0-1 arguments with a primitive type.
 */
export function defaultArgsHashFn(...args) {
    if (args.length === 0)
        return null;
    if (args.length > 1)
        return;
    if (typeof args[0] !== 'string' && typeof args[0] !== 'number' && typeof args[0] !== 'boolean')
        return;
    return String(args[0]);
}
