import { isObject } from './types';
export function deepMerge(...objects) {
    return objects.reduce((res, obj) => {
        if (typeof obj === 'undefined')
            return res;
        if (typeof res === 'undefined')
            res = Array.isArray(obj) ? [] : {};
        if (!isObject(obj) || !isObject(res))
            return obj;
        Object.keys(obj).forEach((key) => {
            res[key] = isObject(obj[key]) ? deepMerge(res[key], obj[key]) : obj[key];
        });
        return res;
    }, undefined);
}
