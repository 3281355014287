var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLBaseElement } from '@exadel/esl/modules/esl-base-element/core';
import { bind, ready, memoize, attr } from '@exadel/esl/modules/esl-utils/decorators';
import { range } from '@exadel/esl/modules/esl-utils/misc/array';
export class ESLDemoMarquee extends ESLBaseElement {
    constructor() {
        super(...arguments);
        this._$active = [];
        this._animateTimer = 0;
    }
    connectedCallback() {
        super.connectedCallback();
        this.startAnimation();
    }
    disconnectedCallback() {
        this.stopAnimation();
        super.disconnectedCallback();
    }
    get $stars() {
        return Array.from(document.querySelectorAll(this.constructor.STARS_SEL));
    }
    get $randomStar() {
        const index = Math.floor(Math.random() * this.$stars.length);
        return this.$stars[index];
    }
    startAnimation() {
        memoize.clear(this, '$stars');
        this.stopAnimation();
        if (this.$stars.length < 2)
            return;
        this._animateTimer = window.setTimeout(this._onIteration, +this.iterationTime);
    }
    stopAnimation() {
        this._animateTimer && window.clearTimeout(this._animateTimer);
    }
    _onIteration() {
        const $candidates = range(+this.targetsNumber, () => this.$randomStar);
        this._$active.forEach((star) => star.classList.remove('animate'));
        $candidates.forEach((star) => star.classList.add('animate'));
        this._$active = $candidates;
        this._animateTimer = window.setTimeout(this._onIteration, +this.iterationTime);
    }
}
ESLDemoMarquee.is = 'esl-d-marquee';
ESLDemoMarquee.STARS_SEL = 'use';
__decorate([
    attr({ defaultValue: '4' })
], ESLDemoMarquee.prototype, "targetsNumber", void 0);
__decorate([
    attr({ defaultValue: '3000' })
], ESLDemoMarquee.prototype, "iterationTime", void 0);
__decorate([
    ready
], ESLDemoMarquee.prototype, "connectedCallback", null);
__decorate([
    memoize()
], ESLDemoMarquee.prototype, "$stars", null);
__decorate([
    bind
], ESLDemoMarquee.prototype, "_onIteration", null);
