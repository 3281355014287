import { SyntheticEventTarget } from '../../../esl-utils/dom/events/target';
import { isElement } from '../../../esl-utils/dom/api';
import { wrap } from '../../../esl-utils/misc/array';
import { ESLIntersectionEvent } from './intersection.event';
export { ESLIntersectionEvent };
/**
 * {@link EventTarget} adapter class for {@link IntersectionObserver}
 * Fires {@link ESLIntersectionEvent} on the observed {@link Element} intersection change
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 *
 * Note: does not support sharing, creates new {@link IntersectionObserver} instance for each {@link ESLIntersectionTarget} instance
 */
export class ESLIntersectionTarget extends SyntheticEventTarget {
    static for(targets, options = {}) {
        const $targets = wrap(targets).filter(isElement);
        const initOptions = Object.assign(Object.assign({}, ESLIntersectionTarget.DEFAULTS), options);
        if ($targets.length)
            return new ESLIntersectionTarget($targets, initOptions);
        // Error handling
        console.warn('[ESL]: ESLIntersectionTarget can not observe %o', targets);
        return null;
    }
    /**
     * Creates {@link ESLResizeObserverTarget} for the {@link Element}.
     * Note the {@link ESLResizeObserverTarget} instances are singletons relatively to the {@link Element}
     */
    constructor(
    /** Observed {@link Element | Element[]} of the {@link ESLIntersectionTarget} instance */
    targets, options) {
        super();
        this.targets = targets;
        this.options = options;
        this.observer$$ = new IntersectionObserver(this.handleChange.bind(this), options);
    }
    /** Internal method to handle {@link IntersectionObserver} entry change */
    handleChange(entries) {
        entries.forEach((entry) => {
            this.dispatchEvent(ESLIntersectionEvent.fromEntry(entry.isIntersecting ? ESLIntersectionEvent.IN : ESLIntersectionEvent.OUT, entry));
            this.dispatchEvent(ESLIntersectionEvent.fromEntry(ESLIntersectionEvent.TYPE, entry));
        });
    }
    addEventListener(event, callback = event) {
        if (typeof event !== 'string')
            event = ESLIntersectionEvent.TYPE;
        if (!ESLIntersectionEvent.isValidEventType(event)) {
            console.warn(`[ESL]: ESLIntersectionTarget does not support '${event}' type`);
            return;
        }
        super.addEventListener(event, callback);
        if (this.getEventListeners(event).length > 1)
            return;
        this.targets.forEach((target) => this.observer$$.observe(target));
    }
    removeEventListener(event, callback = event) {
        if (typeof event !== 'string')
            event = ESLIntersectionEvent.TYPE;
        if (!ESLIntersectionEvent.isValidEventType(event))
            return;
        super.removeEventListener(event, callback);
        if (this.hasEventListener(event))
            return;
        this.observer$$.disconnect();
    }
}
/** Default {@link IntersectionObserverInit} options */
ESLIntersectionTarget.DEFAULTS = { threshold: [0.01] };
