var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLBaseElement } from '../../../esl-base-element/core';
import { listen } from '../../../esl-utils/decorators/listen';
import { ESLEventUtils } from '../../../esl-utils/dom/events';
/**
 * Base class for {@link HTMLSelectElement} wrapper element, implements {@link ESLSelectModel} options source
 */
export class ESLSelectWrapper extends ESLBaseElement {
    constructor() {
        super(...arguments);
        this._mutationObserver = new MutationObserver(this._onTargetMutation.bind(this));
    }
    /** Native select that is wrapped */
    get $select() {
        return this._$select;
    }
    set $select(select) {
        const prev = this._$select;
        this._$select = select;
        this._onTargetChange(select, prev);
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this._mutationObserver.disconnect();
    }
    _onChange(event) { }
    _onListChange() { }
    _onTargetChange(newTarget, oldTarget) {
        this.$$on(this._onChange);
        this._mutationObserver.disconnect();
        const type = this.constructor;
        if (newTarget)
            this._mutationObserver.observe(newTarget, type.observationConfig);
    }
    _onTargetMutation(changes) {
        const isListChange = (change) => change.addedNodes.length + change.removedNodes.length > 0;
        changes.some(isListChange) ? this._onListChange() : this._onChange();
    }
    _onReset(event) {
        if (!event.target || event.target !== this.form)
            return;
        setTimeout(() => this._onChange(event));
    }
    get multiple() {
        return this.$select && this.$select.multiple;
    }
    get options() {
        return this.$select ? Array.from(this.$select.options) : [];
    }
    get selectedOptions() {
        return this.options.filter((item) => item.selected);
    }
    getOption(value) {
        return this.options.find((item) => item.value === value);
    }
    setSelected(value, state) {
        if (!this.$select)
            return;
        const option = this.getOption(value);
        option && (option.selected = state);
        ESLEventUtils.dispatch(this.$select, 'change');
    }
    isSelected(value) {
        const opt = this.getOption(value);
        return !!opt && opt.selected;
    }
    hasSelected() {
        return this.options.some((item) => item.selected);
    }
    isAllSelected() {
        return this.options.every((item) => item.selected || item.disabled);
    }
    setAllSelected(state) {
        if (!this.multiple || !this.$select)
            return;
        this.options.forEach((item) => item.selected = !item.disabled && state);
        ESLEventUtils.dispatch(this.$select, 'change');
    }
    // Proxy select methods and values
    get value() {
        var _a;
        return (_a = this.$select) === null || _a === void 0 ? void 0 : _a.value;
    }
    get values() {
        return this.selectedOptions.map((item) => item.value);
    }
    get form() {
        var _a;
        return (_a = this.$select) === null || _a === void 0 ? void 0 : _a.form;
    }
    get name() {
        var _a;
        return (_a = this.$select) === null || _a === void 0 ? void 0 : _a.name;
    }
    set name(name) {
        this.$select && (this.$select.name = name);
    }
    get required() {
        var _a;
        return (_a = this.$select) === null || _a === void 0 ? void 0 : _a.required;
    }
    set required(required) {
        this.$select && (this.$select.required = required);
    }
    // Validation API values
    get validity() {
        var _a;
        return (_a = this.$select) === null || _a === void 0 ? void 0 : _a.validity;
    }
    get validationMessage() {
        var _a;
        return (_a = this.$select) === null || _a === void 0 ? void 0 : _a.validationMessage;
    }
    get willValidate() {
        var _a;
        return (_a = this.$select) === null || _a === void 0 ? void 0 : _a.willValidate;
    }
    // Validation API methods
    checkValidity() {
        var _a;
        return (_a = this.$select) === null || _a === void 0 ? void 0 : _a.checkValidity();
    }
    reportValidity() {
        var _a;
        return (_a = this.$select) === null || _a === void 0 ? void 0 : _a.reportValidity();
    }
    setCustomValidity(error) {
        var _a;
        (_a = this.$select) === null || _a === void 0 ? void 0 : _a.setCustomValidity(error);
    }
}
ESLSelectWrapper.observationConfig = {
    subtree: true,
    attributes: true,
    attributeFilter: ['value', 'selected', 'disabled'],
    childList: true,
    characterData: true
};
__decorate([
    listen({
        event: 'change',
        target: (el) => el.$select
    })
], ESLSelectWrapper.prototype, "_onChange", null);
__decorate([
    listen({
        event: 'reset',
        target: (el) => el.ownerDocument
    })
], ESLSelectWrapper.prototype, "_onReset", null);
