/** @returns first defined param */
export function defined(...params) {
    for (const param of params) {
        if (param !== undefined)
            return param;
    }
}
/** Finds the closest property descriptor */
export function getPropertyDescriptor(o, prop) {
    let proto = o;
    while (proto) {
        const desc = Object.getOwnPropertyDescriptor(proto, prop);
        if (desc)
            return desc;
        proto = Object.getPrototypeOf(proto);
    }
}
export function extractValues(obj, filter) {
    if (!obj)
        return [];
    const desc = [];
    for (const key in obj) {
        if (filter(obj[key], key))
            desc.push(obj[key]);
    }
    return desc;
}
