var _a;
import { overrideEvent } from '../../../esl-utils/dom/events/misc';
/**
 * Wheel event dispatched by {@link ESLWheelTarget}
 */
export class ESLWheelEvent extends UIEvent {
    constructor(target, wheelInfo) {
        super(_a.TYPE);
        overrideEvent(this, 'target', target);
        Object.assign(this, wheelInfo);
    }
    /** Creates {@link ESLWheelEvent} based on {@link ESLWheelEventInfo} */
    static fromConfig(target, wheelInfo) {
        return new _a(target, wheelInfo);
    }
}
_a = ESLWheelEvent;
ESLWheelEvent.TYPE = 'longwheel';
/** @deprecated Use {@link TYPE} instead */
ESLWheelEvent.type = _a.TYPE;
