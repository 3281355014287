var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { memoize } from '../../decorators/memoize';
/** Deferred object that represents promise with its resolve/reject methods */
export class Deferred {
    constructor() {
        this._status = 'pending';
    }
    /** @returns promise based on {@link Deferred} state*/
    get promise() {
        if (this._status === 'resolved')
            return Promise.resolve(this._value);
        if (this._status === 'rejected')
            return Promise.reject(this._value);
        return new Promise((res, rej) => {
            this._callbacks = [res, rej];
        });
    }
    /** Resolves deferred promise */
    resolve(arg) {
        if (this._status === 'pending') {
            this._value = arg;
            this._status = 'resolved';
            this._callbacks && this._callbacks[0](arg);
        }
        return this;
    }
    /** Rejects deferred promise */
    reject(arg) {
        if (this._status === 'pending') {
            this._value = arg;
            this._status = 'rejected';
            this._callbacks && this._callbacks[1](arg);
        }
        return this;
    }
}
__decorate([
    memoize()
], Deferred.prototype, "promise", null);
/** Creates Deferred Object that wraps promise and its resolve and reject callbacks */
export function createDeferred() {
    return new Deferred();
}
