var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { attr } from '../../esl-utils/decorators';
import { setAttr } from '../../esl-utils/dom/attr';
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLTrigger } from '../../esl-trigger/core';
/**
 * ESlTab component
 * @author Julia Murashko
 *
 * Tab trigger item, usually used in conjunction with a {@link ESLTabs}.
 * Can control any {@link ESLToggleable} instance but is usually used in conjunction with {@link ESLPanel}
 */
let ESLTab = class ESLTab extends ESLTrigger {
    initA11y() {
        const target = this.$a11yTarget;
        if (!target)
            return;
        if (target.hasAttribute('role'))
            return;
        setAttr(target, 'role', 'tab');
    }
    updateA11y() {
        const target = this.$a11yTarget;
        if (!target)
            return;
        if (this.a11yLabelActive !== null || this.a11yLabelInactive !== null) {
            setAttr(target, 'aria-label', this.a11yLabel);
        }
        setAttr(target, 'aria-selected', String(this.active));
        setAttr(target, 'tabindex', this.active ? '0' : '-1');
        if (this.$target && this.$target.id) {
            setAttr(target, 'aria-controls', this.$target.id);
        }
    }
};
ESLTab.is = 'esl-tab';
__decorate([
    attr({ defaultValue: 'show' })
], ESLTab.prototype, "mode", void 0);
__decorate([
    attr({ defaultValue: 'active' })
], ESLTab.prototype, "activeClass", void 0);
ESLTab = __decorate([
    ExportNs('Tab')
], ESLTab);
export { ESLTab };
