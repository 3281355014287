var _a;
import { overrideEvent } from '../../../esl-utils/dom/events/misc';
/** Custom event that {@link ESLResizeObserverTarget} produces */
export class ESLElementResizeEvent extends UIEvent {
    constructor(target) {
        super(_a.TYPE, { bubbles: false, cancelable: false });
        overrideEvent(this, 'target', target);
    }
    /** Creates {@link ESLElementResizeEvent} from {@link ResizeObserverEntry} */
    static fromEntry(entry) {
        const event = new _a(entry.target);
        const { contentRect, borderBoxSize, contentBoxSize, devicePixelContentBoxSize } = entry;
        Object.assign(event, {
            contentRect, borderBoxSize,
            contentBoxSize, devicePixelContentBoxSize
        });
        return event;
    }
}
_a = ESLElementResizeEvent;
ESLElementResizeEvent.TYPE = 'resize';
/** @deprecated Use {@link TYPE} instead */
ESLElementResizeEvent.type = _a.TYPE;
