var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLMixinElement } from '../../esl-mixin-element/core';
import { bind, ready, memoize } from '../../esl-utils/decorators';
import { evaluate } from '../../esl-utils/misc/format';
import { ESLMediaRuleList } from '../../esl-media-query/core';
import { ESLCarousel } from '../core/esl-carousel';
/** Base mixin plugin of {@link ESLCarousel} */
export class ESLCarouselPlugin extends ESLMixinElement {
    /** Plugin configuration attribute value */
    get configValue() {
        const plugin = this.constructor;
        return this.$$attr(plugin.is) || '';
    }
    set configValue(value) {
        const plugin = this.constructor;
        this.$$attr(plugin.is, value);
    }
    /** Plugin configuration query */
    get configQuery() {
        return ESLMediaRuleList.parse(this.configValue, this.$host.media, this.parseConfig);
    }
    /** Active plugin configuration object */
    get config() {
        return this.configQuery.value || {};
    }
    /**
     * Parses plugin media query value term to the config object.
     * Provides the capability to pass a config a stringified non-strict JSON or as a string (mapped to single option configuration).
     *
     * Uses {@link ESLCarouselPlugin.DEFAULT_CONFIG_KEY} to map string value to the config object.
     */
    parseConfig(value) {
        if (!value)
            return null;
        if (value.trim().startsWith('{'))
            return evaluate(value, {});
        const { DEFAULT_CONFIG_KEY } = this.constructor;
        return { [DEFAULT_CONFIG_KEY]: value };
    }
    connectedCallback() {
        const { $host } = this;
        if ($host instanceof ESLCarousel) {
            super.connectedCallback();
            return true;
        }
        else {
            const { is } = this.constructor;
            console.warn('[ESL]: ESLCarousel %s plugin rejected for non correct target %o', is, $host);
            this.$host.removeAttribute(is);
            return false;
        }
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        if (attrName === this.constructor.is) {
            memoize.clear(this, 'configQuery');
            this.onConfigChange();
        }
    }
    /** Callback to be executed on plugin configuration query change (attribute change) */
    onConfigChange() { }
    /** Register mixin-plugin in ESLMixinRegistry */
    static register() {
        ESLCarousel.registered.then(() => super.register());
    }
}
/** Config key to be used if passed non object value */
ESLCarouselPlugin.DEFAULT_CONFIG_KEY = '';
__decorate([
    memoize()
], ESLCarouselPlugin.prototype, "configQuery", null);
__decorate([
    bind
], ESLCarouselPlugin.prototype, "parseConfig", null);
__decorate([
    ready
], ESLCarouselPlugin.prototype, "connectedCallback", null);
