import { loadScript } from '@exadel/esl/modules/esl-utils/dom/script';
import { memoizeFn } from '@exadel/esl/modules/esl-utils/misc/memoize';
function loadSearchScript() {
    return loadScript('gss', 'https://cse.google.com/cse.js?cx=3171f866738b34f02');
}
const onGSSReady = () => {
    return new Promise((resolve) => {
        window.__gcse = {
            parsetags: 'onload',
            initializationCallback: () => resolve()
        };
    });
};
export const requestGss = memoizeFn(() => loadSearchScript().then(onGSSReady));
