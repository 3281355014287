var _a;
import { overrideEvent } from '../../../esl-utils/dom/events/misc';
/**
 * An event that is fired when an element intersects with the device viewport by the {@link ESLIntersectionTarget}.
 * Based on the {@link IntersectionObserverEntry} object.
 */
export class ESLIntersectionEvent extends Event {
    constructor(eventName, target) {
        super(eventName, { bubbles: false, cancelable: false });
        overrideEvent(this, 'target', target);
    }
    /** Validates event name. Accepted values are 'intersects:out', 'intersects:in', 'intersects' */
    static isValidEventType(event) {
        return event === _a.TYPE || event === _a.IN || event === _a.OUT;
    }
    /** Creates {@link ESLIntersectionEvent} from {@link ESLIntersectionEvent}
      * @param eventName - Custom event name ('intersects' by default. Accepted values are 'intersects:out', 'intersects:in', 'intersects').
      * @param entry - The intersection observer entry.
     */
    static fromEntry(eventName, entry) {
        const event = new _a(eventName, entry.target);
        const { boundingClientRect, intersectionRatio, intersectionRect, isIntersecting, rootBounds, time } = entry;
        Object.assign(event, { boundingClientRect, intersectionRatio, intersectionRect, isIntersecting, rootBounds, time });
        return event;
    }
}
_a = ESLIntersectionEvent;
/** Type of event that will be dispatched on viewport exit */
ESLIntersectionEvent.OUT = 'intersects:out';
/** Type of event that will be dispatched on viewport enter */
ESLIntersectionEvent.IN = 'intersects:in';
/** Type of event that will be dispatched on both viewport enter and exit */
ESLIntersectionEvent.TYPE = 'intersects';
/** @deprecated use {@link TYPE} instead */
ESLIntersectionEvent.type = _a.TYPE;
