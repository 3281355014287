var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLMixinElement } from '@exadel/esl/modules/esl-mixin-element/core';
import { attr, listen, memoize } from '@exadel/esl/modules/esl-utils/decorators';
import { getKeyboardFocusableElements } from '@exadel/esl/modules/esl-utils/dom';
import { ESLTraversingQuery } from '@exadel/esl/modules/esl-traversing-query/core';
export class ESLDemoAutofocus extends ESLMixinElement {
    get $focusable() {
        if (this.target)
            return ESLTraversingQuery.first(this.target, this.$host);
        return getKeyboardFocusableElements(this.$host)[0];
    }
    _onShow() {
        const { $focusable } = this;
        if (!$focusable)
            return;
        setTimeout(() => {
            this.$host.open && $focusable.focus();
        }, 500);
    }
}
ESLDemoAutofocus.is = 'esl-d-autofocus';
__decorate([
    attr({ name: ESLDemoAutofocus.is })
], ESLDemoAutofocus.prototype, "target", void 0);
__decorate([
    memoize()
], ESLDemoAutofocus.prototype, "$focusable", null);
__decorate([
    listen('esl:show')
], ESLDemoAutofocus.prototype, "_onShow", null);
