var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ESLScreenBreakpoints_1;
import { ExportNs } from '../../../esl-utils/environment/export-ns';
const registry = new Map();
/**
 * ESL Screen Breakpoints registry
 * @author Yuliya Adamskaya, Alexey Stsefanovich (ala'n)
 *
 * Screen Breakpoint registry is used to provide custom breakpoints for {@link ESLMediaQuery}
 *
 * @implements IMediaQueryPreprocessor statically
 */
let ESLScreenBreakpoints = ESLScreenBreakpoints_1 = class ESLScreenBreakpoints {
    /**
     * Add or replace breakpoint shortcut that can be used inside ESLMediaQuery
     * @param name - name of shortcut
     * @param minWidth - min width for breakpoint
     * @param maxWidth - max width for breakpoint
     */
    static add(name, minWidth, maxWidth) {
        name = name.toLowerCase();
        if (ESLScreenBreakpoints_1.BP_NAME_REGEXP.test(name)) {
            const current = registry.get(name);
            registry.set(name, new ESLScreenBreakpoint(name, minWidth, maxWidth));
            return current;
        }
        throw new Error('The shortcut should consist only of Latin characters and be at least one character long.');
    }
    /** Removes screen breakpoint */
    static remove(name) {
        return registry.delete(name.toLowerCase());
    }
    /** @returns known breakpoint shortcut instance */
    static get(name) {
        return registry.get((name || '').toLowerCase());
    }
    /** All available breakpoints names */
    static get names() {
        return Array.from(registry.keys());
    }
    /** @returns breakpoints shortcut replacement */
    static process(term) {
        const [, sign, bp] = term.match(ESLScreenBreakpoints_1.BP_REGEXP) || [];
        const shortcut = ESLScreenBreakpoints_1.get(bp);
        if (!shortcut)
            return;
        if (sign === '+')
            return shortcut.mediaQueryGE;
        if (sign === '-')
            return shortcut.mediaQueryLE;
        return shortcut.mediaQuery;
    }
};
ESLScreenBreakpoints.BP_REGEXP = /^([+-]?)([a-z]+)/i;
ESLScreenBreakpoints.BP_NAME_REGEXP = /^[a-z]+/i;
ESLScreenBreakpoints = ESLScreenBreakpoints_1 = __decorate([
    ExportNs('ScreenBreakpoints')
], ESLScreenBreakpoints);
export { ESLScreenBreakpoints };
/** ESL Screen Breakpoint description */
export class ESLScreenBreakpoint {
    constructor(name, min, max) {
        this.name = name;
        this.min = min;
        this.max = max;
    }
    get mediaQuery() {
        return `(min-width: ${this.min}px) and (max-width: ${this.max}px)`;
    }
    get mediaQueryGE() {
        return `(min-width: ${this.min}px)`;
    }
    get mediaQueryLE() {
        return `(max-width: ${this.max}px)`;
    }
    toString() {
        return `[${this.name}]: ${this.min} to ${this.max}`;
    }
}
// Defaults
ESLScreenBreakpoints.add('xs', 1, 767);
ESLScreenBreakpoints.add('sm', 768, 991);
ESLScreenBreakpoints.add('md', 992, 1199);
ESLScreenBreakpoints.add('lg', 1200, 1599);
ESLScreenBreakpoints.add('xl', 1600, 999999);
