const managerMap = new Map();
/**
 * Group restriction manager for {@link ESLMedia}
 * Only one media in group can be played
 * Empty group is ignored
 * @author Alexey Stsefanovich (ala'n), Yuliya Adamskaya
 */
export class MediaGroupRestrictionManager {
    /** @internal */
    static get managerMap() {
        return managerMap;
    }
    /** Register instance play state in group */
    static registerPlay(instance) {
        if (instance.group) {
            const current = managerMap.get(instance.group);
            managerMap.set(instance.group, instance);
            if (!current || current === instance || !current.active)
                return;
            if (current.$$fire(current.MANAGED_PAUSE_EVENT))
                current.pause();
        }
    }
    /** Unregister instance */
    static unregister(instance) {
        if (instance.group) {
            const reg = managerMap.get(instance.group);
            if (reg === instance)
                managerMap.delete(instance.group);
        }
    }
}
