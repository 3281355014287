var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { listen } from '../../esl-utils/decorators/listen';
import { afterNextRender } from '../../esl-utils/async/raf';
import { ESLEventUtils } from '../../esl-event-listener/core/api';
import { TAB } from '../../esl-utils/dom/keys';
import { handleFocusChain } from '../../esl-utils/dom/focus';
let instance;
/** Focus manager for toggleable instances. Singleton. */
export class ESLToggleableFocusManager {
    constructor() {
        /** Focus scopes stack. Manger observes only top level scope. */
        this.stack = [];
        if (instance)
            return instance;
        ESLEventUtils.subscribe(this);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        instance = this;
    }
    /** Current focus scope */
    get current() {
        return this.stack[this.stack.length - 1];
    }
    /** Checks if the element is in the known focus scopes */
    has(element) {
        return this.stack.includes(element);
    }
    /** Changes focus scope to the specified element. Previous scope saved in the stack. */
    attach(element) {
        if (element.focusBehavior === 'none' && element !== this.current)
            return;
        // Remove the element from the stack and add it on top
        this.stack = this.stack.filter((el) => el !== element).concat(element);
        // Focus on the first focusable element
        queueMicrotask(() => afterNextRender(() => element.focus({ preventScroll: true })));
    }
    /** Removes the specified element from the known focus scopes. */
    detach(element, fallback) {
        if (element === this.current || document.activeElement === element || element.contains(document.activeElement)) {
            fallback && queueMicrotask(() => afterNextRender(() => fallback.focus({ preventScroll: true })));
        }
        if (!this.has(element))
            return;
        this.stack = this.stack.filter((el) => el !== element);
    }
    /** Keyboard event handler for the focus management */
    _onKeyDown(e) {
        if (!this.current || e.key !== TAB)
            return;
        const { focusBehavior, $focusables } = this.current;
        const $first = $focusables[0];
        const $last = $focusables[$focusables.length - 1];
        const $fallback = this.current.activator || this.current;
        if (focusBehavior === 'loop')
            return handleFocusChain(e, $first, $last);
        if (focusBehavior === 'chain') {
            if ($last && e.target !== (e.shiftKey ? $first : $last))
                return;
            $fallback.focus();
            e.preventDefault();
        }
    }
    /** Focusout event handler */
    _onFocusOut(e) {
        const { current } = this;
        if (!current || !current.contains(e.target))
            return;
        afterNextRender(() => {
            // Check if the focus is still inside the element
            if (current === document.activeElement || current.contains(document.activeElement))
                return;
            if (current.focusBehavior === 'chain') {
                current.hide({ initiator: 'focusout', event: e });
            }
            if (current.focusBehavior === 'loop') {
                current.focus({ preventScroll: true });
            }
        });
    }
}
__decorate([
    listen({ event: 'keydown', target: document })
], ESLToggleableFocusManager.prototype, "_onKeyDown", null);
__decorate([
    listen({ event: 'focusout', target: document })
], ESLToggleableFocusManager.prototype, "_onFocusOut", null);
