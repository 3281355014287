var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ESLOpenState_1;
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLMixinElement } from '../../esl-mixin-element/core';
import { listen, attr, ready } from '../../esl-utils/decorators';
import { ESLMediaQuery } from '../../esl-media-query/core';
import { ESLToggleable } from '../../esl-toggleable/core';
/**
 * ESLOpenState mixin element
 * @author Yuliya Adamskaya, Alexey Stsefanovich (ala'n)
 *
 * ESLOpenState is a custom mixin element that can be used with {@link ESLToggleable}s to request opening/closing it by the media query condition
 */
let ESLOpenState = ESLOpenState_1 = class ESLOpenState extends ESLMixinElement {
    connectedCallback() {
        super.connectedCallback();
        ESLToggleable.registered.then(() => this.onMediaChange());
    }
    /** Handles query change and processes {@link ESLToggleable} toggle event in case query value is matched */
    onMediaChange(event) {
        this.$host.toggle(this.media.matches, {
            event,
            initiator: this.constructor.is,
            activator: this.$host
        });
    }
};
ESLOpenState.is = 'esl-open-state';
__decorate([
    attr({ name: ESLOpenState.is, parser: ESLMediaQuery.for })
], ESLOpenState.prototype, "media", void 0);
__decorate([
    ready
], ESLOpenState.prototype, "connectedCallback", null);
__decorate([
    listen({ event: 'change', target: ($this) => $this.media })
], ESLOpenState.prototype, "onMediaChange", null);
ESLOpenState = ESLOpenState_1 = __decorate([
    ExportNs('OpenState')
], ESLOpenState);
export { ESLOpenState };
