var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { prop } from '../../esl-utils/decorators';
import { setAttr } from '../../esl-utils/dom/attr';
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLEventUtils } from '../../esl-utils/dom/events';
import { CSSClassUtils } from '../../esl-utils/dom/class';
import { ESLMixinRegistry } from './esl-mixin-registry';
import { ESLMixinAttributesObserver } from './esl-mixin-attr';
/**
 * Base class for mixin elements.
 * Mixin elements attaches to the DOM element via attribute.
 * Allows multiple mixin elements per one DOM element
 */
let ESLMixinElement = class ESLMixinElement {
    constructor($host) {
        this.$host = $host;
    }
    /** Callback of mixin instance initialization */
    connectedCallback() {
        ESLMixinAttributesObserver.observe(this);
        ESLEventUtils.subscribe(this);
    }
    /** Callback to execute on mixin instance destroy */
    disconnectedCallback() {
        ESLMixinAttributesObserver.unobserve(this);
        ESLEventUtils.unsubscribe(this);
    }
    /**
     * Callback to handle changing of additional attributes.
     * Happens when attribute accessed for writing independently of the actual value change
     */
    attributeChangedCallback(name, oldValue, newValue) { }
    $$on(event, handler) {
        return ESLEventUtils.subscribe(this, event, handler);
    }
    /** Unsubscribes event listener */
    $$off(...condition) {
        return ESLEventUtils.unsubscribe(this, ...condition);
    }
    /**
     * Gets or sets CSS classes for the `$host`
     * @param cls - CSS classes query {@link CSSClassUtils}
     * @param value - boolean to set CSS class(es) state or undefined to skip mutation
     * @returns current classes state or passed state
     */
    $$cls(cls, value) {
        if (value === undefined)
            return CSSClassUtils.has(this.$host, cls);
        CSSClassUtils.toggle(this.$host, cls, value);
        return value;
    }
    /**
     * Gets or sets attribute for the `$host`.
     * If the `value` param is undefined then skips mutation.
     * @param name - attribute name
     * @param value - string attribute value, boolean attribute state or `null` to delete attribute
     * @returns current attribute value or previous value for mutation
     */
    $$attr(name, value) {
        const prevValue = this.$host.getAttribute(name);
        if (value !== undefined)
            setAttr(this, name, value);
        return prevValue;
    }
    /**
     * Dispatches component custom event on the `$host`.
     * Uses 'esl:' prefix for event name, overridable to customize event namespaces.
     * @param eventName - event name
     * @param eventInit - custom event init. See {@link CustomEventInit}
     */
    $$fire(eventName, eventInit) {
        return ESLEventUtils.dispatch(this.$host, eventName, eventInit);
    }
    /** Register current mixin definition */
    static register() {
        (new ESLMixinRegistry()).register(this);
    }
};
/** Additional observed attributes */
ESLMixinElement.observedAttributes = [];
// Public Registry API
ESLMixinElement.get = ESLMixinRegistry.get;
ESLMixinElement.getAll = ESLMixinRegistry.getAll;
__decorate([
    prop('esl:refresh')
], ESLMixinElement.prototype, "REFRESH_EVENT", void 0);
ESLMixinElement = __decorate([
    ExportNs('Mixin')
], ESLMixinElement);
export { ESLMixinElement };
