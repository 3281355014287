/** Makes a plain copy of obj with properties satisfying the predicate
 * If no predicate provided copies all own properties */
export function copy(obj, predicate = () => true) {
    const result = Object.assign({}, obj || {});
    Object.keys(result).forEach((key) => {
        (!predicate(key, result[key])) && delete result[key];
    });
    return result;
}
/** Makes a flat copy without undefined keys */
export function copyDefinedKeys(obj) {
    return copy(obj || {}, (key, value) => value !== void 0);
}
/** Omits copying provided properties from object */
export function omit(obj, keys) {
    return copy(obj, (key) => !keys.includes(key));
}
