var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLBaseElement } from '../../esl-base-element/core';
import { attr, boolAttr } from '../../esl-utils/decorators';
import { capitalize } from '../../esl-utils/misc/format';
export class ESLRandomText extends ESLBaseElement {
    /** Redraws random text content */
    refresh() {
        // Normalize values
        if (isNaN(this.paragraphs))
            this.paragraphs = 1;
        if (isNaN(this.wordsPerParagraph))
            this.wordsPerParagraph = 100;
        const { paragraphs, wordsPerParagraph, shuffle } = this;
        const words = Math.ceil(paragraphs * wordsPerParagraph);
        this.style.display = 'contents';
        this.innerHTML = ESLRandomText.generateTextHTML(words, wordsPerParagraph, shuffle);
    }
    connectedCallback() {
        super.connectedCallback();
        this.refresh();
    }
    disconnectedCallback() {
        this.innerHTML = '';
        this.style.display = '';
        super.disconnectedCallback();
    }
    attributeChangedCallback(name, oldValue, newValue) {
        this.refresh();
    }
    /**
     * Generates a dummy word.
     * @param random - choose word randomly from {@link DICTIONARY} rather than sequentially
     */
    static generateWord(random = false) {
        if (random)
            return this.DICTIONARY[Math.floor(Math.random() * this.DICTIONARY.length)];
        this.pointer = (this.pointer + 1) % this.DICTIONARY.length;
        return this.DICTIONARY[this.pointer];
    }
    /**
     * Builds a dummy text
     * @param words - number of words in text
     * @param shuffle - shuffle words randomly
     */
    static buildText(words, shuffle = false) {
        const sentences = [];
        while (words > 0) {
            const count = Math.min(this.WORDS_PER_SENTENCE, words);
            const buffer = Array.apply(0, new Array(count));
            const sentence = buffer.map(() => this.generateWord(shuffle)).join(' ');
            sentences.push(capitalize(sentence) + '.');
            words -= this.WORDS_PER_SENTENCE;
        }
        return sentences.join(' ');
    }
    /**
     * Generates a dummy text.
     * @param words - number of words in text
     * @param shuffle - shuffle words randomly
     */
    static generateText(words, shuffle = false) {
        this.pointer = -1;
        return this.buildText(words, shuffle);
    }
    /**
     * Generates a dummy text.
     * @param words - number of words in text
     * @param wordsPerParagraph - number of words in paragraph
     * @param shuffle - shuffle words randomly
     */
    static generateTextHTML(words = this.WORDS_PER_PARAGRAPH, wordsPerParagraph = Math.min(this.WORDS_PER_PARAGRAPH, words), shuffle = false) {
        this.pointer = -1;
        const result = [];
        while (words > 0) {
            const text = this.buildText(Math.min(wordsPerParagraph, words), shuffle);
            result.push(`<p>${text}</p>`);
            words -= wordsPerParagraph;
        }
        return result.join('');
    }
}
ESLRandomText.is = 'esl-random-text';
ESLRandomText.observedAttributes = [
    'paragraphs',
    'words-per-paragraph',
    'shuffle'
];
/** Last used word index in dictionary */
ESLRandomText.pointer = -1;
/** Words dictionary to use in random text generation. */
ESLRandomText.DICTIONARY = [
    'lorem', 'ipsum', 'dolor', 'sit', 'amet',
    'consectetur', 'adipiscing', 'elit', 'curabitur',
    'ultrices', 'et', 'mi', 'suscipit', 'eget', 'vulputate', 'ante',
    'proin', 'vel', 'pretium', 'enim', 'vivamus', 'venenatis', 'eu',
    'urna', 'tempor', 'blandit', 'nullam', 'pellentesque', 'metus',
    'rhoncus', 'mauris', 'mollis', 'neque', 'sed', 'tincidunt', 'tellus',
    'nunc', 'ac', 'nulla', 'ut', 'purus', 'etiam', 'id', 'dui', 'justo',
    'sapien', 'scelerisque', 'viverra', 'ligula', 'aenean', 'porta',
    'condimentum', 'nibh', 'dictum', 'congue', 'odio', 'facilisis',
    'finibus', 'mattis', 'vehicula', 'lacinia', 'risus', 'placerat',
    'augue', 'fringilla', 'at', 'facilisi', 'arcu', 'diam', 'laoreet'
];
/** Number of words in sentence */
ESLRandomText.WORDS_PER_SENTENCE = 10;
/** Number of words in paragraph */
ESLRandomText.WORDS_PER_PARAGRAPH = 100;
__decorate([
    boolAttr()
], ESLRandomText.prototype, "shuffle", void 0);
__decorate([
    attr({ parser: parseFloat, defaultValue: 1 })
], ESLRandomText.prototype, "paragraphs", void 0);
__decorate([
    attr({ parser: parseInt, defaultValue: 100 })
], ESLRandomText.prototype, "wordsPerParagraph", void 0);
