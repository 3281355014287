var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { normalize } from '../core/esl-carousel.utils';
import { ESLCarouselRenderer } from '../core/esl-carousel.renderer';
import { ESLDefaultCarouselRenderer } from './esl-carousel.default.renderer';
/**
 * {@link ESLDefaultCarouselRenderer} extension with positioning logic updated to center active slides inside the carousel area
 *
 * @see ESLDefaultCarouselRenderer
 */
let ESLCenteredCarouselRenderer = class ESLCenteredCarouselRenderer extends ESLDefaultCarouselRenderer {
    /** Size of all active slides */
    get activeSlidesSize() {
        const count = Math.min(this.count, this.size);
        let width = (count - 1) * this.gap;
        for (let i = 0; i < count; i++) {
            const position = normalize(i + this.currentIndex, this.size);
            const $slide = this.$slides[position];
            width += this.vertical ? $slide.offsetHeight : $slide.offsetWidth;
        }
        return width;
    }
    /** Carousel size */
    get carouselSize() {
        return this.vertical ? this.$carousel.clientHeight : this.$carousel.clientWidth;
    }
    /** Carousel padding value */
    get carouselPadding() {
        const carouselStyles = getComputedStyle(this.$carousel);
        return parseFloat(carouselStyles[this.vertical ? 'paddingTop' : 'paddingLeft']);
    }
    /** @returns slide offset by the slide index */
    getOffset(index) {
        const offset = super.getOffset(index);
        return offset - (this.carouselSize - this.activeSlidesSize) / 2 + this.carouselPadding;
    }
};
ESLCenteredCarouselRenderer.is = 'centered';
ESLCenteredCarouselRenderer.classes = ['esl-carousel-centered-renderer', 'esl-carousel-default-renderer'];
ESLCenteredCarouselRenderer = __decorate([
    ESLCarouselRenderer.register
], ESLCenteredCarouselRenderer);
export { ESLCenteredCarouselRenderer };
