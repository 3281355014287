var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLMixinElement } from '../../esl-mixin-element/core';
import { ESLToggleable } from '../../esl-toggleable/core/esl-toggleable';
import { ESLTraversingQuery } from '../../esl-traversing-query/core';
import { listen, attr } from '../../esl-utils/decorators';
import { copyDefinedKeys } from '../../esl-utils/misc/object/copy';
/**
 * ESLRelatedTarget mixin element
 * @author Anastasia Lesun, Alexey Stsefanovich (ala'n)
 *
 * ESLRelatedTarget is a custom mixin element that can be used with {@link ESLToggleable}s to sync their states
 */
let ESLRelatedTarget = class ESLRelatedTarget extends ESLMixinElement {
    /** @returns related toggleable instances */
    get $targets() {
        const { selector } = this;
        if (!selector)
            return [];
        const targets = ESLTraversingQuery.all(selector, this.$host);
        return targets.filter((target) => target instanceof ESLToggleable && target !== this.$host);
    }
    /** Merges params that are used to initiate actions */
    mergeParams(params) {
        return Object.assign(copyDefinedKeys(params), {
            initiator: 'relatedToggleable',
            activator: this.$host
        });
    }
    /** Processes {@link ESLToggleable} show event */
    onShow(e) {
        if (this.action === 'hide' || e.target !== this.$host)
            return;
        const { params } = e.detail;
        this.$targets.forEach((target) => target.show({ params: this.mergeParams(params) }));
    }
    /** Processes {@link ESLToggleable} hide event */
    onHide(e) {
        if (this.action === 'show' || e.target !== this.$host)
            return;
        const { params } = e.detail;
        this.$targets.forEach((target) => target.hide({ params: this.mergeParams(params) }));
    }
};
ESLRelatedTarget.is = 'esl-related-target';
__decorate([
    attr({ name: ESLRelatedTarget.is })
], ESLRelatedTarget.prototype, "selector", void 0);
__decorate([
    attr({ name: ESLRelatedTarget.is + '-action', defaultValue: 'all' })
], ESLRelatedTarget.prototype, "action", void 0);
__decorate([
    listen('esl:show')
], ESLRelatedTarget.prototype, "onShow", null);
__decorate([
    listen('esl:hide')
], ESLRelatedTarget.prototype, "onHide", null);
ESLRelatedTarget = __decorate([
    ExportNs('RelatedTarget')
], ESLRelatedTarget);
export { ESLRelatedTarget };
