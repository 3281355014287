import { hasAttr, setAttr } from '../dom/attr';
import { toKebabCase } from '../misc/format';
function buildConditionalDescriptor(attrName, readOnly) {
    function get() {
        return hasAttr(this, attrName);
    }
    function set(value) {
        setAttr(this, attrName, !!value);
    }
    return readOnly ? { get } : { get, set };
}
const buildAttrName = (propName, dataAttr) => dataAttr ? `data-${toKebabCase(propName)}` : toKebabCase(propName);
/**
 * Decorator to map current property to element boolean (marker) attribute state.
 * Maps boolean type property.
 * @param config - mapping configuration. See {@link BoolAttrDescriptor}
 */
export const boolAttr = (config = {}) => {
    return (target, propName) => {
        const attrName = buildAttrName(config.name || propName, !!config.dataAttr);
        Object.defineProperty(target, propName, buildConditionalDescriptor(attrName, !!config.readonly));
        return {};
    };
};
