var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLToggleablePlaceholder } from '../../esl-toggleable/core';
let ESLPopupPlaceholder = class ESLPopupPlaceholder extends ESLToggleablePlaceholder {
    disconnectedCallback() {
        var _a;
        (_a = this.$origin) === null || _a === void 0 ? void 0 : _a.remove();
        super.disconnectedCallback();
    }
};
ESLPopupPlaceholder.is = 'esl-popup-placeholder';
ESLPopupPlaceholder = __decorate([
    ExportNs('PopupPlaceholder')
], ESLPopupPlaceholder);
export { ESLPopupPlaceholder };
