var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ESLAnimateMixin_1;
import { ESLMixinElement } from '../../esl-mixin-element/core';
import { ready, jsonAttr } from '../../esl-utils/decorators';
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLAnimateService } from './esl-animate-service';
/**
 * ESLAnimateMixin - custom mixin element for quick {@link ESLAnimateService} attaching
 *
 * Use example:
 * `<div esl-animate>Content</div>`
 *
 * Supports additional parameters:
 * `<div esl-animate={repeat: true, ratio: 0.8, cls: 'in'}>Content</div>`
 */
let ESLAnimateMixin = ESLAnimateMixin_1 = class ESLAnimateMixin extends ESLMixinElement {
    mergeDefaultParams() {
        const type = this.constructor;
        return Object.assign({}, type.defaultConfig, this.options);
    }
    connectedCallback() {
        super.connectedCallback();
        this.reanimate();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        ESLAnimateService.unobserve(this.$host);
    }
    attributeChangedCallback(name, oldValue, newValue) {
        this.reanimate();
    }
    /** Reinitialize {@link ESLAnimateService} for target */
    reanimate() {
        ESLAnimateService.unobserve(this.$host);
        ESLAnimateService.observe(this.$host, this.mergeDefaultParams());
    }
};
ESLAnimateMixin.is = 'esl-animate';
ESLAnimateMixin.defaultConfig = {
    force: true
};
__decorate([
    jsonAttr({ name: ESLAnimateMixin.is })
], ESLAnimateMixin.prototype, "options", void 0);
__decorate([
    ready
], ESLAnimateMixin.prototype, "connectedCallback", null);
__decorate([
    ready
], ESLAnimateMixin.prototype, "disconnectedCallback", null);
ESLAnimateMixin = ESLAnimateMixin_1 = __decorate([
    ExportNs('AnimateMixin')
], ESLAnimateMixin);
export { ESLAnimateMixin };
