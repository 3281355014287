var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLBaseElement } from '@exadel/esl/modules/esl-base-element/core';
import { CSSClassUtils } from '@exadel/esl/modules/esl-utils/dom/class';
import { ready } from '@exadel/esl/modules/esl-utils/decorators';
import { requestGss } from './search-script';
export class ESLDemoSearchPageWrapper extends ESLBaseElement {
    connectedCallback() {
        requestGss().then(() => this.afterSearchScriptLoad());
    }
    afterSearchScriptLoad() {
        const loadingAnimationEL = this.querySelector('.animation-loading');
        if (loadingAnimationEL) {
            CSSClassUtils.add(loadingAnimationEL, 'disabled');
        }
    }
}
ESLDemoSearchPageWrapper.is = 'esl-d-search-page-wrapper';
__decorate([
    ready
], ESLDemoSearchPageWrapper.prototype, "connectedCallback", null);
