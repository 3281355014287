var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { SyntheticEventTarget } from '../../../esl-utils/dom/events/target';
import { resolveDomTarget } from '../../../esl-utils/abstract/dom-target';
import { getParentScrollOffsets, isOffsetChanged } from '../../../esl-utils/dom/scroll';
import { isElement } from '../../../esl-utils/dom/api';
import { bind } from '../../../esl-utils/decorators/bind';
import { aggregate } from '../../../esl-utils/async/aggregate';
import { ESLEventListener } from '../listener';
import { ESLWheelEvent } from './wheel.target.event';
export { ESLWheelEvent };
/**
 * Implementation of EventTarget to observe wheel events for inertial scrolling
 */
export class ESLWheelTarget extends SyntheticEventTarget {
    static for(target, settings) {
        const $target = resolveDomTarget(target);
        if (isElement($target))
            return new ESLWheelTarget($target, settings);
        console.warn('[ESL]: ESLWheelTarget can`t observe %o', target);
        return null;
    }
    constructor(target, settings) {
        super();
        this.target = target;
        this.scrollData = [];
        this.config = Object.assign({}, ESLWheelTarget.defaultConfig, settings);
        this.aggregateWheel = aggregate((events) => this.handleAggregatedWheel(events), this.config.timeout);
    }
    /** Handles wheel events */
    _onWheel(event) {
        if (this.config.ignore(event))
            return;
        if (this.config.skipOnScroll) {
            const offsets = getParentScrollOffsets(event.target, this.target);
            this.scrollData = this.scrollData.concat(offsets);
        }
        if (this.config.preventDefault)
            event.preventDefault();
        this.aggregateWheel(event);
    }
    /** Handles aggregated wheel events */
    handleAggregatedWheel(events) {
        const wheelInfo = this.resolveEventDetails(events);
        const isBlocked = isOffsetChanged(this.scrollData);
        this.scrollData = [];
        if (isBlocked)
            return;
        if (Math.abs(wheelInfo.deltaX) >= this.config.distance)
            this.dispatchWheelEvent('x', wheelInfo);
        if (Math.abs(wheelInfo.deltaY) >= this.config.distance)
            this.dispatchWheelEvent('y', wheelInfo);
    }
    /**
     * Dispatches a custom wheel event
     * @param axis - The axis along which the scroll was performed
     * @param wheelInfo - The event detail object
     */
    dispatchWheelEvent(axis, wheelInfo) {
        this.dispatchEvent(ESLWheelEvent.fromConfig(this.target, Object.assign({}, wheelInfo, { axis })));
    }
    /**
     * Resolves long wheel detail object from array of WheelEvent objects
     * @param events - An array of WheelEvent
     * @returns An object containing the resolved event details
     */
    resolveEventDetails(events) {
        const delta = events.reduce((agg, e) => ({
            x: agg.x + this.calculateScrollPixels(e, false),
            y: agg.y + this.calculateScrollPixels(e, true)
        }), { x: 0, y: 0 });
        const duration = events[events.length - 1].timeStamp - events[0].timeStamp;
        return { deltaX: delta.x, deltaY: delta.y, events, duration };
    }
    /**
     * Calculates the scroll pixels for a given wheel event
     * @param event - An event to retrieve scroll value from
     * @param isVertical - A boolean indicating the axis (vertical or horizontal) for scroll calculation
     * @returns The number of pixels scrolled
     */
    calculateScrollPixels(event, isVertical) {
        const { DOM_DELTA_LINE, DOM_DELTA_PAGE } = WheelEvent;
        const deltaValue = (isVertical && event.shiftKey) ? 0 : (isVertical || event.shiftKey) ? event.deltaY : event.deltaX;
        let delta;
        switch (event.deltaMode) {
            case DOM_DELTA_LINE:
                delta = deltaValue * parseInt(window.getComputedStyle(this.target).lineHeight, 10);
                break;
            case DOM_DELTA_PAGE:
                delta = isVertical ? window.innerHeight : window.innerWidth;
                break;
            default:
                delta = deltaValue * window.devicePixelRatio;
        }
        return delta;
    }
    addEventListener(event, callback = event) {
        super.addEventListener(event, callback);
        if (this.getEventListeners().length > 1)
            return;
        ESLEventListener.subscribe(this, this._onWheel, {
            event: 'wheel',
            passive: !this.config.preventDefault,
            target: this.target
        });
    }
    removeEventListener(event, callback = event) {
        super.removeEventListener(event, callback);
        if (!this.hasEventListener(event))
            ESLEventListener.unsubscribe(this);
    }
}
ESLWheelTarget.defaultConfig = {
    skipOnScroll: true,
    distance: 400,
    timeout: 100,
    preventDefault: false,
    ignore: () => false
};
__decorate([
    bind
], ESLWheelTarget.prototype, "_onWheel", null);
