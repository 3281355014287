var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../../esl-utils/environment/export-ns';
import { throttle } from '../../../esl-utils/async/throttle';
import { bind, decorate, listen } from '../../../esl-utils/decorators';
import { ESLWheelEvent, ESLWheelTarget } from '../../../esl-event-listener/core';
import { ESLCarouselPlugin } from '../esl-carousel.plugin';
/**
 * {@link ESLCarousel} wheel control plugin mixin
 * Switch slides by mouse wheel
 *
 * @author Alexey Stsefanovich (ala'n)
 */
let ESLCarouselWheelMixin = class ESLCarouselWheelMixin extends ESLCarouselPlugin {
    /** @returns true if the plugin should track vertical wheel */
    get isVertical() {
        switch (this.config.direction) {
            case 'x': return false;
            case 'y': return true;
            default: return this.$host.state.vertical;
        }
    }
    /** @returns true if the plugin should track passed event */
    isEventIgnored(e) {
        if (e.shiftKey === this.isVertical)
            return true;
        const { ignore } = this.config;
        return !!ignore && !!e.target.closest(ignore);
    }
    /** Handles auxiliary events to pause/resume timer */
    _onWheel(e) {
        var _a;
        if (!this.$host || this.$host.animating)
            return;
        const delta = this.isVertical ? e.deltaY : e.deltaX;
        const direction = delta > 0 ? 'next' : 'prev';
        (_a = this.$host) === null || _a === void 0 ? void 0 : _a.goTo(`${this.config.command || 'slide'}:${direction}`);
    }
};
ESLCarouselWheelMixin.is = 'esl-carousel-wheel';
ESLCarouselWheelMixin.DEFAULT_CONFIG_KEY = 'command';
ESLCarouselWheelMixin.DEFAULT_CONFIG = {
    command: 'slide',
    ignore: '[contenteditable]',
    direction: 'auto',
    preventDefault: true
};
__decorate([
    bind
], ESLCarouselWheelMixin.prototype, "isEventIgnored", null);
__decorate([
    listen({
        event: ESLWheelEvent.TYPE,
        target: (plugin) => ESLWheelTarget.for(plugin.$host, {
            distance: 10,
            preventDefault: plugin.config.preventDefault,
            ignore: plugin.isEventIgnored
        })
    }),
    decorate(throttle, 400)
], ESLCarouselWheelMixin.prototype, "_onWheel", null);
ESLCarouselWheelMixin = __decorate([
    ExportNs('Carousel.Wheel')
], ESLCarouselWheelMixin);
export { ESLCarouselWheelMixin };
