var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLWheelTarget, ESLWheelEvent } from '@exadel/esl/modules/esl-event-listener/core';
import { listen } from '@exadel/esl/modules/esl-utils/decorators';
import { ESLDemoLogArea } from '../log-area/log-area';
/** Area element for logging long wheel event data */
export class ESLDemoWheelArea extends ESLDemoLogArea {
    onWheel(e) {
        const { name } = ESLWheelEvent;
        const { type, axis, deltaX, deltaY } = e;
        this.log({ name, type, data: { axis, deltaX, deltaY } });
    }
}
ESLDemoWheelArea.is = 'esl-d-wheel-area';
__decorate([
    listen({
        event: 'longwheel',
        target: ESLWheelTarget.for
    })
], ESLDemoWheelArea.prototype, "onWheel", null);
