var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLSwipeGestureTarget, ESLSwipeGestureEvent } from '@exadel/esl/modules/esl-event-listener/core';
import { listen } from '@exadel/esl/modules/esl-utils/decorators';
import { ESLDemoLogArea } from '../log-area/log-area';
/** Area element for logging swipe event data */
export class ESLDemoSwipeArea extends ESLDemoLogArea {
    onSwipe(e) {
        const { name } = ESLSwipeGestureEvent;
        const { type, direction, distanceX, distanceY, distance, angle } = e;
        this.log({ name, type, data: { direction, distanceX, distanceY, distance, angle } });
    }
}
ESLDemoSwipeArea.is = 'esl-d-swipe-area';
__decorate([
    listen({
        event: 'swipe',
        target: ESLSwipeGestureTarget.for
    })
], ESLDemoSwipeArea.prototype, "onSwipe", null);
