var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { dispatchCustomEvent } from '../../esl-utils/dom/events/misc';
import { ESLEventListener } from './listener';
import { getDescriptors, isEventDescriptor, initDescriptor } from './descriptors';
let ESLEventUtils = class ESLEventUtils {
    static subscribe(host, eventDesc = { auto: true }, handler = eventDesc) {
        if (typeof eventDesc === 'string')
            eventDesc = { event: eventDesc };
        if (typeof handler === 'function') {
            if (typeof eventDesc === 'function' && eventDesc !== handler)
                throw new Error('[ESL] Multiple handler functions passed');
            return ESLEventListener.subscribe(host, handler, eventDesc);
        }
        return getDescriptors(host, handler).flatMap((desc) => ESLEventListener.subscribe(host, desc, eventDesc));
    }
};
/**
 * Dispatches custom event. Alias for {@link dispatchCustomEvent}
 * @see dispatchCustomEvent
 */
ESLEventUtils.dispatch = dispatchCustomEvent;
/** Gets {@link ESLListenerDescriptorFn}s of the passed object */
ESLEventUtils.descriptors = getDescriptors;
/**
 * Gets auto {@link ESLListenerDescriptorFn}s of the passed object
 *
 * @deprecated alias for `descriptors(host, {auto: true})`
 */
ESLEventUtils.getAutoDescriptors = (host) => getDescriptors(host, { auto: true });
/**
 * Decorates passed `key` of the `host` as an {@link ESLListenerDescriptorFn} using `desc` meta information
 * @param host - object holder of the function to decorate
 * @param key - string key of the function in holder object
 * @param desc - descriptor meta information to assign
 * @returns ESLListenerDescriptorFn created on the host
 */
ESLEventUtils.initDescriptor = initDescriptor;
/** Type guard to check if the passed function is typeof {@link ESLListenerDescriptorFn} */
ESLEventUtils.isEventDescriptor = isEventDescriptor;
/**
 * Gets currently subscribed listeners of the host
 * @param host - host object (listeners context) to associate subscription
 * @param criteria - optional set of criteria {@link ESLListenerCriteria} to filter listeners list
 */
ESLEventUtils.listeners = ESLEventListener.get;
/**
 * Unsubscribes {@link ESLEventListener}(s) from the object
 * @param host - host element that stores subscriptions (listeners context)
 * @param criteria - optional set of criteria {@link ESLListenerCriteria} to filter listeners to remove
 */
ESLEventUtils.unsubscribe = ESLEventListener.unsubscribe;
ESLEventUtils = __decorate([
    ExportNs('EventUtils')
], ESLEventUtils);
export { ESLEventUtils };
