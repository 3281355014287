var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../../esl-utils/environment/export-ns';
import { bind, listen, ready } from '../../../esl-utils/decorators';
import { ESLCarouselPlugin } from '../esl-carousel.plugin';
import { ESLCarouselSlideEvent } from '../../core/esl-carousel.events';
/**
 * {@link ESLCarousel} autoplay (auto-advance) plugin mixin
 * Automatically switch slides by timeout
 *
 * @author Alexey Stsefanovich (ala'n)
 */
let ESLCarouselAutoplayMixin = class ESLCarouselAutoplayMixin extends ESLCarouselPlugin {
    constructor() {
        super(...arguments);
        this._timeout = null;
    }
    get active() {
        return !!this._timeout;
    }
    connectedCallback() {
        if (super.connectedCallback()) {
            this.start();
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.stop();
    }
    onConfigChange() {
        this.start();
    }
    /** Activates the timer to send commands */
    start() {
        this.stop();
        this._timeout = window.setTimeout(this._onInterval, this.config.timeout);
    }
    /** Deactivates the timer to send commands */
    stop() {
        this._timeout && window.clearTimeout(this._timeout);
        this._timeout = null;
    }
    /** Handles next timer interval */
    _onInterval() {
        var _a;
        (_a = this.$host) === null || _a === void 0 ? void 0 : _a.goTo(this.config.command);
        this._timeout = window.setTimeout(this._onInterval, this.config.timeout);
    }
    /** Handles auxiliary events to pause/resume timer */
    _onInteract(e) {
        // Slide change can only delay the timer, but not start it
        if (e.type === ESLCarouselSlideEvent.AFTER && !this.active)
            return;
        if (['mouseover', 'focusin'].includes(e.type)) {
            this.stop();
        }
        else {
            this.start();
        }
    }
};
ESLCarouselAutoplayMixin.is = 'esl-carousel-autoplay';
ESLCarouselAutoplayMixin.DEFAULT_CONFIG_KEY = 'timeout';
__decorate([
    ready
], ESLCarouselAutoplayMixin.prototype, "connectedCallback", null);
__decorate([
    bind
], ESLCarouselAutoplayMixin.prototype, "_onInterval", null);
__decorate([
    listen(`mouseout mouseover focusin focusout ${ESLCarouselSlideEvent.AFTER}`)
], ESLCarouselAutoplayMixin.prototype, "_onInteract", null);
ESLCarouselAutoplayMixin = __decorate([
    ExportNs('Carousel.Autoplay')
], ESLCarouselAutoplayMixin);
export { ESLCarouselAutoplayMixin };
