var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { prop, attr, boolAttr, listen } from '@exadel/esl/modules/esl-utils/decorators';
import { CSSClassUtils } from '@exadel/esl/modules/esl-utils/dom/class';
import { afterNextRender } from '@exadel/esl/modules/esl-utils/async/raf';
import { parseNumber } from '@exadel/esl/modules/esl-utils/misc/format';
import { ESLTraversingQuery } from '@exadel/esl/modules/esl-traversing-query/core';
import { ESLToggleable } from '@exadel/esl/modules/esl-toggleable/core';
import { requestGss } from '../../search/search-script';
export class ESLDemoSearchBox extends ESLToggleable {
    constructor() {
        super(...arguments);
        this.closeOnOutsideAction = true;
    }
    onShow(params) {
        CSSClassUtils.add(this, this.postCls);
        requestGss().then(() => this.showSearchElements(params));
    }
    showSearchElements(params) {
        afterNextRender(() => super.onShow(params));
        if (this.autofocus) {
            const $focusEl = ESLTraversingQuery.first(this.firstFocusable, this);
            $focusEl && window.setTimeout(() => $focusEl.focus(), parseNumber(this.postClsDelay));
        }
        const loadingAnimationEL = this.querySelector('.animation-loading');
        if (loadingAnimationEL) {
            CSSClassUtils.add(loadingAnimationEL, 'disabled');
        }
    }
    onHide(params) {
        super.onHide(params);
        window.setTimeout(() => {
            var _a;
            CSSClassUtils.remove(this, this.postCls);
            (_a = this.activator) === null || _a === void 0 ? void 0 : _a.focus({ preventScroll: true });
        }, parseNumber(this.postClsDelay));
    }
    onFocusOut(e) {
        if (!e.relatedTarget || !e.currentTarget || e.currentTarget.contains(e.relatedTarget))
            return;
        this.hide({ hideDelay: 100 });
        this.activator = e.relatedTarget;
    }
}
ESLDemoSearchBox.is = 'esl-d-search-box';
__decorate([
    attr()
], ESLDemoSearchBox.prototype, "postCls", void 0);
__decorate([
    attr()
], ESLDemoSearchBox.prototype, "postClsDelay", void 0);
__decorate([
    attr({ defaultValue: '::find(input)' })
], ESLDemoSearchBox.prototype, "firstFocusable", void 0);
__decorate([
    boolAttr()
], ESLDemoSearchBox.prototype, "autofocus", void 0);
__decorate([
    prop()
], ESLDemoSearchBox.prototype, "closeOnOutsideAction", void 0);
__decorate([
    listen({
        event: 'focusout',
        target: '::parent(.header-search)'
    })
], ESLDemoSearchBox.prototype, "onFocusOut", null);
