var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../../esl-utils/environment/export-ns';
import { listen, memoize } from '../../../esl-utils/decorators';
import { ESLTraversingQuery } from '../../../esl-traversing-query/core';
import { ESLCarousel } from '../../core/esl-carousel';
import { ESLCarouselPlugin } from '../esl-carousel.plugin';
import { ESLCarouselSlideEvent } from '../../core/esl-carousel.events';
/**
 * Slide Carousel Link plugin mixin to bind carousel positions
 */
let ESLCarouselRelateToMixin = class ESLCarouselRelateToMixin extends ESLCarouselPlugin {
    get event() {
        return this.config.proactive ? ESLCarouselSlideEvent.BEFORE : ESLCarouselSlideEvent.AFTER;
    }
    /** @returns ESLCarousel target to share state changes */
    get $target() {
        const $target = ESLTraversingQuery.first(this.config.target);
        return ($target instanceof ESLCarousel) ? $target : null;
    }
    onConfigChange() {
        // Listener event change is not handled by resubscribe automatically
        this.$$off(this._onSlideChange);
        memoize.clear(this, '$target');
        this.$$on(this._onSlideChange);
    }
    /** Handles event that fires when the carousel slides state is changed. */
    _onSlideChange(e) {
        if (!this.$target || e.activator === this)
            return;
        this.$target.goTo(this.$host.activeIndex, {
            activator: this
        });
    }
};
ESLCarouselRelateToMixin.is = 'esl-carousel-relate-to';
ESLCarouselRelateToMixin.DEFAULT_CONFIG_KEY = 'target';
__decorate([
    memoize()
], ESLCarouselRelateToMixin.prototype, "$target", null);
__decorate([
    listen({ event: ($this) => $this.event })
], ESLCarouselRelateToMixin.prototype, "_onSlideChange", null);
ESLCarouselRelateToMixin = __decorate([
    ExportNs('Carousel.RelateTo')
], ESLCarouselRelateToMixin);
export { ESLCarouselRelateToMixin };
