var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { BaseProvider } from '../../core/esl-media-provider';
import { HTMLMediaProvider } from './media-provider';
/**
 * Simple Audio API provider for {@link ESLMedia}
 * @author Alexey Stsefanovich (ala'n)
 */
let AudioProvider = class AudioProvider extends HTMLMediaProvider {
    createElement() {
        const el = document.createElement('audio');
        el.src = this.src;
        return el;
    }
    get defaultAspectRatio() {
        return 0;
    }
};
AudioProvider.providerName = 'audio';
AudioProvider.urlPattern = /\.(mp3|wav|aac)(\?|$)/;
AudioProvider = __decorate([
    BaseProvider.register
], AudioProvider);
export { AudioProvider };
