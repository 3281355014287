import { setAttr } from './attr';
/**
 * Creates new script element with specified attributes
 * Note: as the script will be added dynamically, it will be treated as async by the browser
 */
const createScript = (id, src, attrs) => {
    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    Object.entries(attrs).forEach(([name, value]) => setAttr(script, name, value));
    return script;
};
/**
 * Common function that loads script async
 * @param id - unique script id that is used as a marker to prevent future load
 * @param src - script src (url) to load
 * @param attrs - additional attributes to set on the script tag
 */
export function loadScript(id, src, attrs = {}) {
    return new Promise((resolve, reject) => {
        const script = (document.getElementById(id) || createScript(id, src, attrs));
        const state = script.getAttribute('state');
        switch (state) {
            case 'success':
                resolve(new Event('load'));
                break;
            case 'error':
                reject(new Event('error'));
                break;
            default:
                script.addEventListener('load', (e) => {
                    script.setAttribute('state', 'success');
                    resolve(e);
                });
                script.addEventListener('error', (e) => {
                    script.setAttribute('state', 'error');
                    reject(e);
                });
        }
        if (!script.parentNode) {
            const firstScriptTag = document.querySelector('script') || document.querySelector('head title');
            if (firstScriptTag && firstScriptTag.parentNode) {
                firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
            }
            else {
                reject('Page document structure is incorrect');
            }
        }
    });
}
