import { isElement } from './api';
/**
 * A Rect describes the size and position of a rectangle.
 */
export class Rect {
    static from(rect) {
        var _a, _b;
        if (isElement(rect))
            return Rect.from(rect.getBoundingClientRect());
        return new this((_a = rect.x) !== null && _a !== void 0 ? _a : rect.left, (_b = rect.y) !== null && _b !== void 0 ? _b : rect.top, rect.width, rect.height);
    }
    /**
     * The static method checks the equality of the two Rect instances.
     * @param rect1 - first instance of Rect
     * @param rect2 - second instance of Rect
     */
    static isEqual(rect1, rect2) {
        return rect1.isEqual(rect2);
    }
    /**
     * The static method returns intersection Rect of two Rect instances
     * @param rect1 - first instance of Rect
     * @param rect2 - second instance of Rect
     */
    static intersect(rect1, rect2) {
        return rect1.intersect(rect2);
    }
    constructor(x, y, width, height) {
        this.x = x || 0;
        this.y = y || 0;
        this.width = Math.max(0, width || 0);
        this.height = Math.max(0, height || 0);
    }
    /** @returns the top coordinate value of the Rect (has the same value as y) */
    get top() {
        return this.y;
    }
    /** @returns the left coordinate value of the Rect (has the same value as x) */
    get left() {
        return this.x;
    }
    /** @returns the right coordinate value of the DOMRect */
    get right() {
        return this.x + this.width;
    }
    /** @returns the bottom coordinate value of the Rect */
    get bottom() {
        return this.y + this.height;
    }
    /** @returns the center X coordinate value of the Rect */
    get cx() {
        return this.x + this.width / 2;
    }
    /** @returns the center Y coordinate value of the Rect */
    get cy() {
        return this.y + this.height / 2;
    }
    /** @returns the numeric value of rectangle area */
    get area() {
        return Math.abs(this.height * this.width);
    }
    grow(incrementX, incrementY = incrementX) {
        return new Rect(this.x - incrementX, this.y - incrementY, this.width + 2 * incrementX, this.height + 2 * incrementY);
    }
    shrink(decrementX, decrementY = decrementX) {
        return this.grow(-decrementX, -decrementY);
    }
    /** @returns new {@link Rect} that is shifted by x and y axis */
    shift(x = 0, y = 0) {
        return new Rect(this.x + x, this.y + y, this.width, this.height);
    }
    /** @returns new {@link Rect} that is resized by x and y axis */
    resize(xDelta = 0, yDelta = 0) {
        return new Rect(this.x, this.y, this.width + xDelta, this.height + yDelta);
    }
    /** @returns new {@link Rect} an intersection of current and passed Rectangle */
    intersect(rect) {
        const top = Math.max(this.top, rect.top);
        const left = Math.max(this.left, rect.left);
        const bottom = Math.min(this.bottom, rect.bottom);
        const right = Math.min(this.right, rect.right);
        const width = Math.max(right - left, 0);
        const height = Math.max(bottom - top, 0);
        return new Rect(left, top, width, height);
    }
    /** @returns if the passed rect is equal to current */
    isEqual(rect) {
        return this.x === rect.x && this.y === rect.y && this.width === rect.width && this.height === rect.height;
    }
}
