/** {@link ESLCarousel} event that represents slide change event */
export class ESLCarouselSlideEvent extends Event {
    constructor(type, init) {
        super(type, {
            bubbles: true,
            cancelable: ESLCarouselSlideEvent.BEFORE === type,
            composed: true
        });
        Object.assign(this, init);
    }
    /** @returns first index of before sate */
    get indexBefore() {
        return this.indexesBefore[0];
    }
    /** @returns first index of after state */
    get indexAfter() {
        return this.indexesAfter[0];
    }
    /** @returns list of slides that are active before the change */
    get $slidesBefore() {
        return this.indexesBefore.map((index) => this.target.slideAt(index));
    }
    /** @returns list of slides that are active after the change */
    get $slidesAfter() {
        return this.indexesAfter.map((index) => this.target.slideAt(index));
    }
    static create(type, init) {
        return new ESLCarouselSlideEvent(ESLCarouselSlideEvent[type], init);
    }
}
/** {@link ESLCarouselSlideEvent} cancelable event type dispatched before slide change (pre-event) */
ESLCarouselSlideEvent.BEFORE = 'esl:before:slide-change';
/** {@link ESLCarouselSlideEvent} event type dispatched before carousel is going to change active slide (post-event) */
ESLCarouselSlideEvent.CHANGE = 'esl:slide-change';
/** {@link ESLCarouselSlideEvent} event type dispatched after slide change (post-event) */
ESLCarouselSlideEvent.AFTER = 'esl:after:slide-change';
/** {@link ESLCarousel} event that represents slide configuration change */
export class ESLCarouselChangeEvent extends Event {
    constructor(type, init) {
        super(type, {
            bubbles: true,
            cancelable: false,
            composed: true
        });
        this.initial = false;
        this.added = [];
        this.removed = [];
        Object.assign(this, init);
    }
    static create(init) {
        return new ESLCarouselChangeEvent(ESLCarouselChangeEvent.TYPE, init);
    }
}
/** {@link ESLCarouselSlideEvent} event type dispatched on carousel config changes */
ESLCarouselChangeEvent.TYPE = 'esl:carousel:change';
